<template >
  <div class="login">
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full space-y-8 shadow-2xl bg-blue-900 p-6 rounded-lg">
        <div>
          <span class="text-xl pl-2"><img
              src="../assets/logo.png"
              alt=""
            />
          </span>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-white">
            Inicia sesión con tu cuenta
          </h2>
        </div>

        <input
          type="hidden"
          name="remember"
          value="true"
        />
        <div class="rounded-md shadow-xl -space-y-px">
          <div>
            <label
              for="usuario"
              class="sr-only"
            >Usuario</label>
              <input
                v-model="usuario.login"
                id="usuario"
                name="usuario"
                type="text"
                autocomplete="text"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="usuario"
              />
          </div>
          <div>
            <label
              for="contraseña"
              class="sr-only"
            >Contraseña</label>
              <input
                v-model="usuario.password"
                id="contraseña"
                name="contraseña"
                type="password"
                autocomplete="current-password"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Contraseña"
              />
          </div>
        </div>
        <div>
          <label class="ml-2 block text-base text-white">
            {{mensaje}}
          </label>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label
              for="remember_me"
              class="ml-2 block text-sm text-white"
            >
              Recordarme
              </label>
          </div>
        </div>
        <div>
          <button
            @click="IniciarSesion()"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            </span>
            Iniciar sesión
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import axios from "axios";

export default {
  name: "login",
  components: {
    //  Menu,
  },
  setup() {
    const store = useStore();

    /*variables */
    const resultLogin = computed(() => store.state.login.usuario);
    const mensaje = ref("");

    const usuario = ref({
      login: "",
      password: ""
    });

    const IniciarSesion = async () => {
      //console.log("usuario");
      //console.log(usuario.value);

      await store.dispatch("login/login", usuario.value);

      if (resultLogin.token == "" || resultLogin.token == undefined) {
        mensaje.value = "Usuario o contraseña incorrectos / No autorizado";
      }

      //console.log("entro cambiarNombref");
    };

    const mostrar = computed(() => {
      return store.state.login.mostrar;
    });

    //const login = computed(() => store.state.loginCorrecto);
    //console.log(login.value);
    /*variables */
    //console.log(axios);

    /*const loginLogin = () => {
      store.commit("changeLogin");
      store.commit("login", ["login"]);
    };*/

    return {
      IniciarSesion,
      usuario,
      mostrar,
      resultLogin,
      mensaje
    };
  }
};
</script>
<style scoped>
#login {
  background: url("../assets/logos/JB2021.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}
</style>
