<template>
  <div id="nav" v-if="!mostrar">
    <!--<router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>-->

    <!--<Menu></Menu>-->
  </div>

  <div v-show="mostrar">
    <!--<h1>Login ppal</h1>
  <button @click="cambiar()">session</button>
  <Login></Login>-->
    <!--<h1>otros</h1>
    <label for="">usuario</label>
    <input type="text" name="" id="" v-model="usuario.login"> 
    <label for="">passs</label>
    <input type="text" name="" id="" v-model="usuario.password"> 
    <button @click="IniciarSesion()">session</button>-->

    <Login></Login>
    
  </div>

  <router-view />
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import Login from "../src/views/Login.vue";
import Menu from "../src/components/Menu.vue";

export default {
  name: "App",
  components: {
    Login,
    Menu,
  },

  setup() {
    const store = useStore();

    const usuario = ref({
      login: "",
      password: "",
    });

    const IniciarSesion = () => {
      //console.log("usuario");
      //console.log(usuario.value);

      store.dispatch("login/login", usuario.value);
      //console.log("entro cambiarNombref");
    };

    const mostrar = computed(() => {
      return store.state.login.mostrar;
    });

    return { mostrar, IniciarSesion,usuario };
  },
};
</script>


