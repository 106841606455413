<template>
  <div :class="fondoclass" class="">
    <div :class="classModal" class="h-4/5">
      <div
        class="
          shadow-sm
          flex
          justify-center
          w-8
          h-8
          border-2 border-red-600
          rounded
          text-center
        "
      >
        <button @click="cerrarPagareContrato">
          <p class="font-bold tect-red-500">x</p>
        </button>
      </div>
      <hr />

      <div class="overflow-auto h-full text-justify">
        <div class="grid justify-items-center ">
          <img :src="img" alt="" />
        </div>
        <p class="font-bold text-center">Pagaré</p>
        <br />
        <br />

        <p>Nosotros _____________________________________________________, con cédula de 
          ciudadanía número ________________ y ___________________________, mayores de 
          edad, domiciliados en la ciudad de ________________________________, departamento de 
          ______________________________, declaramos:
        </p>
        <br />
        <p> <span class="font-bold" >Primera. Objeto.</span>
          Que por virtud del presente título valor pagaremos incondicionalmente, a la orden de 
          FLOR 'ES ELEGANCIA INTIMA S.A.S NIT. 900.365.409-1, o a quien represente sus derechos, la suma de
          _______________________ ($ ). En la ciudad de Sabaneta, Antioquia. 
          El pago lo realizaremos el día _______________ (.) de ___________del año____________.
        </p>
        <br />

        <p> <span class="font-bold" >Segunda. Intereses.</span>
          En caso de mora reconoceremos y pagaremos por cada día de retardo en el pago del 
          capital debido, interés de mora a la tasa máxima autorizada por la ley ______________________________
          ( %). Así mismo, en caso de cobro judicial y/o extrajudicial, serán de nuestro cargo los gastos y/o costos 
          en que incurra el acreedor.
        </p>

        <br />

        <p> <span class="font-bold" >Tercero. Cláusula aceleratoria.</span>
         . El tenedor podrá declarar insubsistente los plazos de esta obligación o de 
          las cuotas que constituyen el saldo y exigir su pago inmediato judicial o extrajudicialmente en los siguientes 
          casos: a) Cuando los deudores incumplan una cualquiera de las obligaciones derivadas del presente 
          documento) b) Cuando los deudores inicien proceso de insolvencia. 
        </p>

         <br />

        <p> <span class="font-bold" >Carta de Instrucciones.</span>
         Deconformidad con lo establecido en el artículo 622 del Código de Comercio, autorizoexpresa e
         irrevocablemente alacreedor para llenar elpresentepagaréen losespacios dejados en blanco. El
         título valor será llenado por el acreedor sin previo aviso y de acuerdo con las siguientes instrucciones:

        </p>


        <p>
          1. La fecha de vencimiento del pagaré será la fecha en que el acreedor 
          complete los espacios en blanco.
        </p>
        <br/>
        <p>
          2. El valor del presente pagaré será la suma de dinero que cualquiera de los
          obligados, de manera conjunta o independiente debamos a FLOR'ES
          ELEGANCIAINTIMAS.A.SNIT.900.365.409-1
        </p>
        <br/>
        <p>
          3. El valor contemplado en el numeral 2, se aumentará en lo que constituya el valor de los 
          intereses de mora y los gastos o costos de cobranza, judiciales y/o extrajudiciales en que 
          incurra el acreedor.
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p>
          ----------------------- </p>
          <p>Firma del deudor </p>
          <p>C.C</p>
          <br/>
          <br/>
           <br/>
          <br/>
        <!--<p class="text-center">---------------------------------------------------------------------------- </p>-->

         <p class="font-bold text-center">CONTRATO DE COMPRA PARA LA REVENTA</p>
         <br/>

         <p>De una parte, FLOR 'ES ELEGANCIA INTIMA S.A.S. Sociedad existente conforme a las leyes de la República de Colombia, identificada con 
            NIT. 900.365.409-1, representada legalmente como aparece en el registro mercantil quien otorga poder para la firma del presente contrato a 
            OMAR ALONSO DUQUE GOMEZ , mayor de edad, identificado conforme aparece al pie de su firma, (en adelante la "Compañía"), y de otra 
            parte, (______________________________________________), mayor de edad, identificada conforme aparece al pie de su firma (en adelante 
            la "Asesora " y conjuntamente con la Compañía, las "Partes"), manifiestan que han decidido celebrar el presente Contrato de Compra para la 
            Reventa (en adelante el "Contrato") previas las siguientes:</p>
             <br/>

             <p class="font-bold">CONSIDERACIONES:</p>

             <br/>

         <p>
          PRIMERO: La Compañía, en nombre propio, por cuenta propia y bajo su crédito personal, se dedica a la realización de actividades encaminadas a 
          la comercialización de Productos.
        </p>

        <p>
          SEGUNDO: La Asesora es una persona que cuenta con la capacidad para llevar a cabo de forma autónoma e independiente la comercialización de 
          los Productos que adquiera de la Compañía.
        </p>    

        <p>
         TERCERO Es intención de las Partes aunar esfuerzos para que la Compañía venda, en nombre propio, por cuenta propia y bajo su crédito 
         personal, los Productos a la Asesora.
        </p>    
        <br/>

        <p>
        En consecuencia de lo anterior, las Partes, acuerdan las siguientes:
        </p>  

        <br/>
        <p class="font-bold">CLÁUSULAS:</p>  

        <br/>
        
        <p>
          PRIMERA- DEFINICIONES: Para efectos de este contrato se establecen las siguientes definiciones que tendrán el significado que a continuación 
          se indica:
        </p>

        <br/>
        <p>
          1. Asesora: Significa la persona que adquiere productos de la Compañía para incorporarlos dentro de su propia actividad comercial y 
          revenderlos a consumidores finales.
        </p>
        <br/>
        <p>
          2. Consumidor: Toda persona natural o jurídica que, como destinatario final, adquiera, disfrute o utilice un determinado producto, cualquiera 
          que sea su naturaleza para la satisfacción de una necesidad propia, privada, familiar, o doméstica y empresarial, cuando no esté ligada 
          intrínsecamente a su actividad económica. Se entenderá incluido en el concepto de Consumidor el de usuario.

        </p>
        <br/>
        <p>
          3. La Compañía: FLOR 'ES ELEGANCIA INTIMA S.A.S NIT. 900.365.409-1, propietaria de la marca “Juana Bonita"
        </p>
        <br/>

        <p>
         4. Pérdidas: Significan los costos y gastos en los que incurra cada una de las Partes en el desarrollo de sus actividades según este contrato 
          y que no hayan generado las Ganancias esperadas por ellas.

        </p>
        <br/>

        <p>
         5. Política de Tratamiento de Datos Personales: Es el documento que regula el almacenamiento y tratamiento de los datos personales de 
        la Asesora , el cual se encuentra publicado en la página web de la Compañíahttps://asesoras.juanabonita.com.co/

        </p>
        <br/>

        <p>
         6. Productos: Significa los productos de la Compañía, producidos, importados y/o comercializados bajo sus propias marcas o marcas de 
        terceros debidamente autorizados a la Compañía.
        </p>
        <br/>

        <p>
          7. Programa de incentivos y plan comercial: Es el conjunto de beneficios que la Compañía le reconoce a la Asesora , los cuales están 
          contenidos en boletines, comunicaciones u otro tipo de documentos, mensajes de datos o cualquier medio de comunicación autorizado en 
          el presentecontrato.
        </p>
        <br/>

        <p>
          8. Proveedor: Quien de manera habitual, directa o indirectamente, ofrezca, suministre, distribuya o comercialice productos con o sin ánimo 
          de lucro. Para los efectos del presente Contrato, frente al Consumidor final, la Asesora ostenta la calidad de Proveedor.
        </p>
        <br/>

        <p>
          9. Solicitud de Crédito: Significa el documento (formulario virtual o físico) mediante el cual la Compradora solicita un cupo de crédito. La 
          Compradora conoce y acepta que los términos y condiciones de la Solicitud de Crédito podrán ser modificados libremente por la 
          Compañía cuando así lo estime conveniente. La Solicitud de Crédito incluye o podrá incluir documentos adicionales o complementarios 
          como contratos, firmas solidarias, codeudores y otros.
        </p>
        <br/>

        <p>
         10. Portal Web: Es el sitio web de propiedad exclusiva de FLOR 'ES ELEGANCIA INTIMA S.A.S , que ofrece a los usuarios, de forma fácil e 
         integrada, el acceso a una serie de recursos y de servicios relacionados con el objeto social de FLOR 'ES ELEGANCIA INTIMA S.A.S 
         tales como la posibilidad de realizar pedidos de productos, registrarse como Asesora, solicitar crédito, tener acceso al catálogo virtual, 
         entre otros.
        </p>
        <br/>

         <p>
         SEGUNDA-OBJETO: El presente Contrato tiene por objeto regular la relación comercial entre la Compañía y la Asesora, quien se dedica a la 
         compra de los Productos para su reventa o comercialización a Consumidores. La Asesora podrá ofrecer y comercializar los Productos que 
         compre a la Compañía a través del sistema de venta directa utilizando única y exclusivamente los canales y políticas de comercialización 
         autorizados por la Compañía. El incumplimiento de lo aquí previsto dará derecho a la Compañía para terminar este Contrato y cancelar el cupo 
        de crédito otorgado a la Asesora.
        </p>
        <p>
        Las Partes convienen en forma expresa que la Asesora no tiene exclusividad con la Compañía para adelantar su negocio.
        </p>
        <br/>
        <p>
         TERCERA-SOLICITUD DE CRÉDITO: Para la compra de los Productos, la Asesora ha solicitado a la Compañía, mediante la firma de este 
         documento, el otorgamiento de un cupo de crédito. En consecuencia, la Asesora autoriza expresamente a la Compañía para que en virtud de 
         esta solicitud, verifique la veracidad de la información que ésta ha registrado y para que con base en el estudio de crédito que realice en cuanto a 
         su capacidad de endeudamiento, se reserve el derecho de asignarle un cupo de crédito para la adquisición de Productos.
        La Asesora declara que tiene conocimiento que los demás términos y condiciones del cupo de crédito, no regulados en este Contrato, se 
        encuentran en la página Web de la Compañía https://asesoras.juanabonita.com.co/ El crédito deberá ser pagado por la Asesora en los plazos 
        fijados por la Compañía. El cupo de crédito en ningún caso implica desembolso de dinero efectivo en favor de la Asesora, es un cupo
        exclusivamente destinado a la compra de productos.
        La Compañía se reserva el derecho de revocar el cupo de crédito cuando lo estime conveniente y a modificar de tiempo en tiempo tanto la 
        solicitud de Crédito como los términos y condiciones del mismo.
        Con la firma de este Contrato la Asesora autoriza a la Compañía para que con la periodicidad que corresponda compense el pago de las cuotas 
        originadas en el cupo de crédito, contra cualquier suma de dinero que debiera ser pagada a la Asesora derivada de este Contrato o cualquier otra 
        relación comercial. Como garantía del pago del crédito a la Compañía, la Asesora, con la suscripción de este Contrato, se obliga a cumplir con 
        todas las obligaciones, las cuales serán reportadas mensualmente a las Centrales de Riesgo Crediticio.
        </p>
        <br/>

        <p>
         CUARTA-ACTIVIDADES EN DESARROLLO DEL CONTRATO: En desarrollo del Contrato, la COMPAÑÍA realizará las siguientes actividades:
        </p>
         <br/>

          <p>
            1. Reconocer los descuentos en el precio de los Productos para su posterior reventa de conformidad con el plan comercial
        </p>
        <br/>

         <p>
            2. Entregar los productos a La Asesora en la dirección suministrada.
        </p>
        <br/>

         <p>
            3. Cumplir con las demás obligaciones que establezca la ley y las demás normas aplicables.
        </p>
        <br/>
        <br/>

        <p>
            En desarrollo del Contrato la ASESORA realizará las siguientes actividades:
        </p>
        <br/>

        <p>
            1. Asumir por su propia cuenta y riesgo todos los costos y gastos que demanden sus actividades para promover y promocionar el negocio 
            objeto de este Contrato.

        </p>
        <br/>
        <p>
            2. Mantenerse activa en el sistema mediante el cumplimiento de las acciones del Plan Comercial.

        </p>
        <br/>

         <p>
            3. Informar a la Compañía de las situaciones que tenga conocimiento y que puedan afectar este Contrato y en especial cualquier actividad, 
            relación o situación que pueda significar financiación o apoyo en cualquiera de sus formas al terrorismo, al narcotráfico y/o al lavado de
            activos.

        </p>
        <br/>

        <p>
           4. Actuar en su propio nombre, como comerciante independiente, bajo su exclusiva responsabilidad, prohibiendo expresamente que adopte 
           una razón comercial común a la Compañía.
        </p>
        <br/>

        <p>
           5. Respetar los derechos de propiedad intelectual sobre el portal web, destinándolo exclusivamente al uso del catálogo virtual y sus
           funcionalidades.

        </p>
        <br/>

        <p>
           6. Conocer el Plan Comercial y programa de incentivos y sus modificaciones.
        </p>
        <br/>

        <p>
           7. Actualizar frente a la Compañía sus datos de contacto cada vez que sufran cualquier modificación.
        </p>
        <br/>

         <p>
           Scripts, texto, material gráfico, fotografías, imágenes, diseños, video, audio, escritos y otros elementos que aparezcan como parte del portal web 
           (en conjunto, "Contenido"), son de propiedad exclusiva de FLOR 'ES ELEGANCIA INTIMA S.A.S titular de la marca Juana Bonita, y están 
           protegidos por las leyes de derechos de autor en Colombia y otras leyes y tratados internacionales ratificados por Colombia. Todo el contenido es 
           suministrado por FLOR 'ES ELEGANCIA INTIMA S.A.S. con una licencia limitada como un servicio para quien visita el portal y solo se podrá usar 
           para fines personales. Esta licencia limitada está sujeta a las siguientes restricciones: usted no podrá copiar, reproducir, publicar, transmitir, 
           distribuir, ejecutar, exhibir, hacer público, modificar, crear trabajos derivados, vender, conceder una licencia o explotar de alguna otra manera 
           este portal web, alguno de sus contenidos o software relacionado; FLOR 'ES ELEGANCIA INTIMA S.A.S puede revocar esta licencia limitada en 
           cualquier momento por cualquier motivo o sin motivo aparente. 
        </p>
        <br/>

        <p>
           El uso reiterado del catálogo virtual en el Portal Web de FLOR 'ES ELEGANCIA INTIMA S.A.S., no le otorga ningún derecho de propiedad u 
           otros a La Asesora sobre el mismo, pues su uso se restringe estrictamente al autorizado de acuerdo con la licencia limitada de uso descrita 
            anteriormente.

        </p>
        <br/>

        <p>
         QUINTA-DESCUENTOS EN LA COMPRA DE PRODUCTOS: Los descuentos que son otorgados a la Asesora por la compra de los Productos 
          para su posterior reventa, se definen en el Plan Comercial. Tales descuentos podrán ser fijados libremente y modificados de tiempo en tiempo 
          por parte de la Compañía.
        </p>
         <br/>

        
         <p>
         SEXTA-CAUSALES DE TERMINACIÓN: El presente Contrato se podrá terminar en cualquier tiempo, por una cualquiera de las siguientes 
         causales:
        </p>
         <br/> 

          <p>
         1. Por decisión de una de las Partes en cualquier tiempo.
        </p>
         <br/> 
         <p>
         2. Por fallecimiento de la Asesora.
        </p>
         <br/> 
         <p>
         3. Por entregar la Asesora información o publicidad engañosa, ocultar o tergiversar información del negocio.
        </p>
         <br/> 
        <p>
         4. Por decisión de la Compañía en caso de incumplimiento total o parcial de la Asesora de cualquiera de las cláusulas y obligaciones 
         derivadas del presente Contrato.
        </p>
         <br/>

         <p>
         5. Por la inexactitud o falta de veracidad de las declaraciones consagradas en la cláusula octava de este Contrato.
        </p>
         <br/> 

         <p>
         SÉPTIMA-INDEPENDENCIA DE LAS PARTES: La Asesora declara expresa y libremente que no existe vínculo laboral entre ella y la Compañía, 
          ni entre el personal que cada una de ellas pudiera tener y la Compañía. La Asesora y la Compañía disponen de autonomía y libertad técnica, 
          administrativa y directiva para la ejecución del presente Contrato y cada una de las partes será la única responsable del personal que pudiera 
          requerir para su ejecución, lo cual realizará cada parte en su propio nombre, por su cuenta y riesgo, sin que ninguna de tales situaciones 
          signifique que la Compañía adquiera responsabilidad por tales actos o contratos ejecutados por la Asesora.
        </p>
         <br/> 

         <p>
         Las partes declaran y aceptan expresamente que entienden claramente que bajo ninguna circunstancia están realizando funciones propias de la 
        agencia comercial conforme se define en el Código de Comercio de Colombia, y se abstendrán en todo momento de actuar en nombre y 
        representación de la Compañía.

        </p>
         <br/> 

         <p>
            OCTAVA-DECLARACIONES: La Asesora declara que:        </p>
         <br/> 

          <p>
         1. Los recursos y dineros destinados para el pago de sus obligaciones crediticias y/o de los pedidos realizados a la Compañía, así como las 
          ganancias y/o cualquier recurso que derive de su actividad comercial, no provienen directa o indirectamente (ni serán destinados para) 
          lavado de activos, financiación del terrorismo ni ninguna otra actividad ilícita;
        </p>
         <br/> 
          <p>
        2. No pertenece a ningún grupo terrorista o al margen de la ley;
        </p>
         <br/> 

          <p>
            3. No se encuentra reportada en ninguna lista internacional expedida por el Consejo de Seguridad de las Naciones Unidas ni en las demás 
            que tengan carácter vinculante para Colombia por actividades relacionadas con lavados de activos y/o financiación del terrorismo.
        </p>
         <br/>

         <p>
            4. Que conoce los términos y condiciones para acceder y manejar el catálogo virtual desde el portal web.

        </p>
         <br/> 

         <p>
           Parágrafo. La Asesora conoce y acepta que las declaraciones consagradas en esta cláusula son ciertas tanto al momento de la firma de este 
          Contrato como durante toda su vigencia, obligándose así a dar aviso oportuno a la Compañía de los cambios que deban realizarse para 
          mantener actualizada y vigente la información de las partes.

        </p>
         <br/> 

        <p>
            NOVENA- La Compañía enviará los productos adquiridos por La Asesora en los términos de la presente clausula a la dirección suministrada en la 
            solicitud de crédito. La Asesora será responsable por cualquier cambio en el lugar de envío, así como de actualizar sus datos. La Asesora
            autoriza a que los productos sean entregados a personas diferentes quienes en su nombre y representación reciban en la dirección indicada.
       </p>
         <br/> 

        <p>
           PARÁGRAFO PRIMERO: Las partes acuerdan que una vez el pedido sea entregado en la dirección suscrita, ser obligación de La Asesora cumplir el pago.
       </p>
         <br/> 


         <p>
           DÉCIMA-DOCUMENTOS COMPLEMENTARIOS: Las Partes acuerdan que hacen parte integral del presente Contrato lo siguiente:
       </p>
         <br/> 

         <p>
           1. El Plan Comercial y programa de incentivos.
       </p>
         <br/> 

         <p>
          2. Los requisitos, términos y condiciones de la página web de la Compañía https://asesoras.juanabonita.com.co/politicas-de-privacidad/
       </p>
         <br/> 

         <p>
          3. La Solicitud de Crédito
       </p>
         <br/> 

        <p>
          4. La Política de Tratamiento de Datos Personales. https://asesoras.juanabonita.com.co/politicas-de-privacidad/
       </p>
         <br/>

         <p>
           DÉCIMA PRIMERA-NOTIFICACIONES: Salvo aquellas comunicaciones que según éste Contrato podrá hacer la Compañía en su página web o 
          en cualquier otro medio virtual elegido por la Compañía, las comunicaciones a las Partes en relación con este Contrato se dirigirán:
       </p>
         <br/> 

         <p>
           A la COMPAÑÍA:
       </p>
         <br/> 


         <p>
           Destinatario: FLOR 'ES ELEGANCIA INTIMA S.A.S  </p>
         <p>  Dirección: CALLE 57 SUR 48 # 24 SABANETA (ANTIOQUIA)</p>
          <p>Teléfono: 4487696</p>
         <p>Email: contacto@juanabonita.com.co</p>

         <br/> 

         <p>
           A la ASESORA según los datos suministrados en la Solicitud de Crédito.
       </p>
         <br/> 

         <p>
           En calidad de asesora autorizo para que “la notificación” a que hace referencia la Ley 1266 de 2008, en su artículo 12, Ley 2157 de 2021 
            y demás normas concordantes, se pueda surtir a través de mensaje de datos y para ello suministro y declaro el siguiente 
            correo electrónico:-------------------------------------------------- ; Y número telefónico para mensaje de texto y 
            WhatsApp: --------------------------. Certifico que los datos personales suministrados por mí, son veraces, 
            completos, exactos, actualizados, reales y comprobables. Por tanto, cualquier error en la información suministrada será de mi única y
            exclusiva responsabilidad, lo que exonera a Flores Elegancia Íntima de su responsabilidad ante las autoridades judiciales y/o 
            administrativas. Declaro que he leído y comprendido a cabalidad el contenido de la presente Autorización, y acepto la finalidad en ella
            descrita y las consecuencias que se derivan de ella
       </p>
         <br/> 

         <p>
           En caso que la Asesora cambie sus datos de contacto o sus datos comerciales, deberá informárselo inmediatamente a la Compañía.
       </p>
         <br/> 

           <p>
           Acepto recibir información válidamente sea por mensaje de texto, correo electrónico o llamada telefónica, WhatsApp y demás medios 
            presenciales o no presenciales, sean o no electrónicos. Declaro conocer que dicha autorización puede ser revocada y/o modificada 
            en los términos establecidos en La Ley 2300 de 2023, a través de https://canalescontacto.juanabonita.com.co

       </p>
         <br/> 

         <p>
           DÉCIMA SEGUNDA-PROTECCIÓN DE DATOS PERSONALES: Con la suscripción de este Contrato, la Asesora declara que conoce los derechos 
          y deberes que le asisten como titular de sus datos personales, a conocer, rectificar y actualizar sus datos personales que reposan en las bases de 
          datos de la Compañía.

       </p>
         <br/>

         <p>
           Así mismo, da su consentimiento previo, expreso e informado y autoriza a la Compañía, como responsable del tratamiento, para:
       </p>
         <br/>

         <p>
           1. Incorporar o insertar sus datos personales en las bases de datos de la Compañía que corresponda, utilizando mecanismos de seguridad 
          suficientes, sin desconocer las obligaciones y deberes establecidos en la legislación vigente sobre protección de datos personales y 
          atendiendo a las Políticas de Tratamiento de Datos Personales adoptadas por la Compañía en cumplimiento de la ley;
       </p>
         <br/>

         <p>
           2. Correlacionar las bases de datos en las cuales reposan sus datos personales, con otras bases de datos de la Compañía o de terceros que 
          desarrollen actividades en calidad de encargados o que cuenten con información estadística y de comportamiento del sector, siempre y 
          cuando dicha correlación tenga como propósito, entre otros, analizar, evaluar, generar datos estadísticos, indicadores, resultados 
          comerciales, investigación de mercados, variables del negocio de la Asesora,
       </p>
         <br/>

         <p>
           3. En general tratar sus datos personales de conformidad con la ley.
       </p>
         <br/>
         <br/>

         <p>
           PARÁGRAFO PRIMERO: La Asesora manifiesta que ha sido informada que los datos de naturaleza sensible, los cuales incluyen los referidos a 
          su estado de salud y a sus datos biométricos, como la firma, foto y huella, se solicitan por la Compañía con el fin de asegurar la debida 
          identificación de la Compradora y que, dada su naturaleza, no está obligada a suministrarlos, pero que acepta su tratamiento, al haber conocido 
          la finalidad para la cual son requeridos por la Compañía.
       </p>
         <br/>


         <p>
          PARÁGRAFO SEGUNDO: La Asesora autoriza voluntaria e irrevocablemente a FLOR 'ES ELEGANCIA INTIMA S.A.S (Juana Bonita) a 
          reportar la información de sus obligaciones vencidas y de su comportamiento crediticio. Acepta recibir información válidamente sea por mensaje 
          de texto al celular, correo electrónico o llamada telefónica, y demás medios presenciales o no presenciales, sean o no electrónicos, vinculados a 
          la Asesora.
       </p>
         <br/>


         <p>
          DÉCIMA TERCERA: Las Partes declaran que este Contrato es el único vigente entre ellas y reemplaza y deja sin efectos cualquier otro contrato 
          de cualquier otro tipo que pudiera existir entre las Partes.
       </p>
         <br/>

         <p>
          En constancia se firma el presente Contrato en Sabaneta, a los xxx días del mes xxx de 2022:
       </p>
         <br/>
         <br/>

         
         
         <div class="grid grid-cols-2">
          <div><p>La Compañía</p><br/>
          <p>Omar Alonso Duque Gómez</p>
          <p>Representante Legal</p>
          </div>
          <!-- ... -->
          <div><p>La Asesora</p><br/>
               <p>Nombre asesora</p>
               <p> CC: xxxxx</p>
          </div>
        </div>
         

      </div>
    </div>
  </div>
</template>
<script>
import img from "../assets/logos/LogoJuana.png";
import { ref } from "@vue/reactivity";
import store, { useStore } from "vuex";
export default {
  name: "noticeModal",

  setup() {
    const store = useStore();
    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center  w-4/4 relative mx-2 z-50  px-3 py-2 flex items-center justify-center rounded absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-8 lg:mt-10"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch p-3 left-0 top-0 bottom-0 right-0  fixed bg-opacity-50 bg-gray-900"
    );
    const cerrarPagareContrato = () => {
      store.commit("closeModalPagareContato");
    };
    return {
      classModal,
      fondoclass,
      cerrarPagareContrato,
      img,
    };
  },
};
</script>

<style>
</style>