<template>
  <div :class="fondoclass">
    <div :class="classModal">
      <img :src="ruta" class="w-24 animate-pulse" alt="icon" />
      <p class="text-center font-bold 3xl border-b-2 border-gray-200">
        {{ descripcion }}
      </p>

      <p class="text-center text-base">{{ msg }}</p>
      <button
        class="
          bg-blue-400
          border
          shadow
          rounded
          focus:outline-none
          focus:ring-2 focus:ring-blue-600
          focus:border-transparent
          h-8
          w-10
          text-white
          mt-1
        "
        @click="closeModal()"
      >
        OK!
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import img01 from "../assets/xred.png";
import img0 from "../assets/succes.png";
import img02 from "../assets/peligro.png";
import { useStore } from "vuex";
import { watchEffect } from "@vue/runtime-core";
export default {
  name: "modalPropos",
  props: {
    tipos: String,
    msg1: String,
    titulo: String,
  },
  setup(props) {
    const store = useStore();
    const img = ref(props.tipos);
    const msg = ref(props.msg1);
    const ruta = ref("");
    const descripcion = ref(props.titulo);
    watchEffect(() => {
      img.value = props.tipos;
      msg.value = props.msg1;
      descripcion.value = props.titulo;
      
       switch (img.value) {
        case "0": ruta.value = img0;  break;
        case "1": ruta.value = img01; break;
        case "2": ruta.value = img02; break;
        default:  ruta.value = img01; break;
      }
    });

    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center w-76  lg:w-96  h-64 relative mx-auto z-50 max-w-lg px-3 py-12 flex items-center justify-center rounded-full absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-48 lg:mt-52"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50 justify-between w-full bg-gray-900"
    );

    const closeModal = () => {
      store.commit("closeModal");
    };
    return {
      ruta,
      msg,
      descripcion,
      classModal,
      closeModal,
      fondoclass,
    };
  },
};
</script>
