import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import VueLoading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
//import VueSweetAlert from 'vue-sweetalert2'
//import 'sweetalert2/dist/sweetalert2.min.css'


import "@/assets/css/tailwind.css";

createApp(App).use(store).use(router).use(VueAxios,axios).mount('#app')


