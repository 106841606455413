import { createStore } from 'vuex'
import { login } from '../modules/login.js'
import axios from 'axios'
import datos from "../store/file.json";
import router from "../router/index.js";

export default createStore({
  state: {
    mostrarModal: false,
    mostrarModalConfirmacion: false,
    mostrarModalLoading: false,
    teminosModalV: false,
    pagareContratoModal: false,
    ModalV: false,
    ModalDireccionAux: true,
    ModalDireccionEntrega: true,
    ModalPagoAnticipado: false,
    result: "",
    idTransaccion: "",
    nota: "",
    idZona: "",
    direccionCompleta: "",
    direccionCompletaEntrega: "",
    codigoOTP: "",
    cedulaAsesora: "",
    celularAsesora: "",
    fechaNacimiento: "",
    respuestaCuestionario: "",
    respuestaGrabar: "",
    mensajeReingreso: "",
    resultCifinAnticipado: "",
    resultEvaluacionReal: "",
    nombreAsesora: "",
    fechaExpedicion: "",
    resultCifin: {
      nombre: "",
      resultado: "",
    },
    ctrlVinculacionOtpManual: "N",

    asesora: {},

    resultGetCedula: {
      nombre: "",
      cedula: "",
      saldo: "",
    },

    preguntas: [],
    departamentos: [],
    ciudades: [],
    poblados: [],
    nomenclaturas: [],
    datosFormAnt: {},

  },
  mutations: {
    closeModal(state) {
      state.mostrarModal = !state.mostrarModal
    },

    closeModalConfirmacion(state) {
      state.mostrarModalConfirmacion = !state.mostrarModalConfirmacion
    },

    closeModalLoading(state) {
      state.mostrarModalLoading = !state.mostrarModalLoading
    },

    closeModalLoadingAux(state) {
      state.mostrarModalLoading = !state.mostrarModalLoading
    },

    terminosModal(state) {
      state.teminosModalV = !state.teminosModalV

    },

    closeModalPagareContato(state) {
      state.pagareContratoModal = !state.pagareContratoModal

    },


    ModalCodigo(state) {
      state.ModalV = !state.ModalV
    },

    ModalPagoAnticipado(state) {
      state.ModalPagoAnticipado = !state.ModalPagoAnticipado
    },


    ModalDireccion(state) {
      state.ModalDireccionAux = !state.ModalDireccionAux
    },

    ModalDireccionEntrega(state) {
      state.ModalDireccionEntrega = !state.ModalDireccionEntrega
    },

    setModalDirecciones(state) {
      state.ModalDireccionEntrega = true
      state.ModalDireccionAux = true
    },

    setModalLoadingAux(state) {
      state.mostrarModalLoading = false
    },

    setResultGrabar(state, payload) {
      state.result = payload
    },

    setResultOTP(state, payload) {
      state.preguntas = payload
      state.idTransaccion = state.preguntas.IdTransaccion
      state.codigoOTP = state.preguntas.controlMensaje.substring(3, 10)

      if (state.codigoOTP == "MDATA") {
        state.ctrlVinculacionOtpManual = "S"
      } else {
        state.ctrlVinculacionOtpManual = "N"
      }

      //state.ctrlVinculacionOtpManual = "N"

    },

    setResultReenvioOTP(state, payload) {
      state.codigoOTP = payload
    },

    setResultFinal(state, payload) {
      state.respuestaGrabar = payload

    },

    setResultAsesora(state, payload) {
      state.asesora = payload

      /*if (state.asesora.primerNombre != '') {
        state.nombreAsesora = ''
      }*/

    },

    setResultCifin(state, payload) {
      state.resultCifin = payload
      //console.log("Nombre asesora")
      //console.log(state.resultCifin)
      state.nombreAsesora = state.resultCifin.nombreTitular
    },


    setResultCifinAnticipado(state, payload) {
      state.resultCifinAnticipado = payload
      state.nombreAsesora = state.resultCifinAnticipado.nombreTitular
      state.resultEvaluacionReal = state.resultCifinAnticipado.mensajeEvaluacionReal
      //state.asesora.primerNombre = state.resultCifinAnticipado.nombreTitular

    },


    setCedulaValidar(state, payload) {
      state.nota = payload

      state.mensajeReingreso = 'NUEVA'

      if (state.nota.trim() == 'MINIMO4CAMP') {
        state.mensajeReingreso = 'REINGRESO'
      }

      if (state.nota.trim() == 'NUEVANOVERIFICADA') {
        state.mensajeReingreso = 'NUEVACA'
      }

      if (state.nota.trim() == 'REINCORPORACION') {
        state.mensajeReingreso = 'REINCORPORACION'
      }

    },

    setUsuario(state, payload) {
      if (payload.length > 0) {
        state.resultGetCedula.nombre = payload[0].primerNombre
        state.resultGetCedula.cedula = payload[0].numDocumento
        state.resultGetCedula.saldo = 100
      } else {
        state.resultGetCedula.nombre = ''
        state.resultGetCedula.cedula = ''
        state.resultGetCedula.saldo = ''
      }
    },

    setPreguntas(state, payload) {

      state.preguntas = payload;

    },

    setIdZona(state, payload) {
      state.idZona = payload;
      //getDepartamentos(state.idZona);
    },

    setDepartamentos(state, payload) {
      state.departamentos = payload;
      //getDepartamentos(state.idZona);
    },

    setCiudades(state, payload) {
      state.ciudades = payload;
      //getDepartamentos(state.idZona);
    },

    setPoblados(state, payload) {
      state.poblados = payload;

    },

    setDireccionCompleta(state, payload) {
      state.direccionCompleta = ''
      state.direccionCompleta = payload;
    },

    setDireccionCompletaEntrega(state, payload) {
      state.direccionCompletaEntrega = ''
      state.direccionCompletaEntrega = payload;
    },

    setNomenclatura(state, payload) {
      state.nomenclaturas = payload;
      //getDepartamentos(state.idZona);
    },

    setCuestionario(state, payload) {
      state.asesora = payload;

      /*console.log("rpta Cuestionario")
      console.log(state.asesora)*/

    },

    /*vinculacionManual(state) {
      state.ctrlVinculacionOtpManual = 'S'
    },*/

    LimpiarVariables(state) {
      state.mostrarModal = false,
        state.mostrarModalLoading = false,
        state.teminosModalV = false,
        state.pagareContratoModal = false,
        state.ModalV = false,
        state.ModalDireccionAux = true,
        state.ModalDireccionEntrega = true,
        state.ModalPagoAnticipado = false,
        state.result = "",
        state.nombreAsesora = "",
        //state.idTransaccion= "",
        state.nota = "",
        state.idZona = "",
        state.direccionCompleta = "",
        state.direccionCompletaEntrega = "",
        state.codigoOTP = "",
        state.cedulaAsesora = "",
        state.celularAsesora = "",
        state.fechaNacimiento = "",
        state.respuestaCuestionario = "",
        state.respuestaGrabar = "",
        state.mensajeReingreso = "",
        
        state.mostrarModalConfirmacion = false,
        state.fechaExpedicion = ""
        state.resultEvaluacionReal = "",
        
      //resultCifin: ""
      state.resultCifin = {
        nombre: "",
        resultado: "",
      },
        state.ctrlVinculacionOtpManual = "N"

      state.asesora = {},

        state.resultGetCedula = {
          nombre: "",
          cedula: "",
          saldo: "",
        },

        state.preguntas = [],
        state.departamentos = [],
        state.ciudades = [],
        state.poblados = [],
        state.nomenclaturas = [],
        state.datosFormAnt = {}

    },

    LimpiarVariablesSinModal(state) {
        state.result = "",
        state.nombreAsesora = "",
        //state.idTransaccion= "",
        state.nota = "",
        state.idZona = "",
        state.direccionCompleta = "",
        state.direccionCompletaEntrega = "",
        state.codigoOTP = "",
        state.cedulaAsesora = "",
        state.celularAsesora = "",
        state.fechaNacimiento = "",
        state.respuestaCuestionario = "",
        state.respuestaGrabar = "",
        state.mensajeReingreso = "",
        state.fechaExpedicion = "",
        state.resultEvaluacionReal = "",
        
      //resultCifin: ""
      state.resultCifin = {
        nombre: "",
        resultado: "",
      },
        state.ctrlVinculacionOtpManual = "N"

      state.asesora = {},

        state.resultGetCedula = {
          nombre: "",
          cedula: "",
          saldo: "",
        },

        state.preguntas = [],
        state.departamentos = [],
        state.ciudades = [],
        state.poblados = [],
        state.nomenclaturas = [],
        state.datosFormAnt = {}

    },


  },
  actions: {

    async grabarInformacion({ commit, state }, asesora) {

      let usuario = localStorage.getItem("usuario");
      let token = localStorage.getItem("token");

      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          "?primerNombre=" +
          asesora.primerNombre +
          "&segundoNombre=" +
          asesora.segundoNombre +
          "&primerApellido=" +
          asesora.primerApellido +
          "&segundoApellido=" +
          asesora.segundoApellido +
          "&cedula=" +
          asesora.cedula +
          "&usuario=" +
          usuario +
          "&token=" +
          token +
          '',

        )
        .then((response) => {
          let data = response.data
          commit("setResultGrabar", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async verificarCedulaConsulta({ commit, state }, parametros) {
      let usuario = localStorage.getItem("usuario");
      let token = localStorage.getItem("token");

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          "?cedula=" +
          parametros.cedula +
          "&check=" +
          parametros.check +
          "&usuario=" +
          usuario +
          "&token=" +
          token +
          '',

        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultCifin", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },


    async probar({ commit, state }) {
      commit("setPreguntas", state.preguntas);

    },


    async enviarInfo({ commit, state }, data) {
      let usuario = localStorage.getItem("usuario");
      let token = localStorage.getItem("token");

      // console.log(data.value);
      //let prueba = user = JSON.parse(data.value);

      const dataFinal = []

      data.value.forEach(function (data) {
        dataFinal.push(data)
      })

      const cuestionario = "ctrl"

      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?cuestionario=" +
          cuestionario +
          "&idTransaccion=" +
          state.idTransaccion +
          "&idCedulaAsesora=" +
          state.cedulaAsesora +
          "&detaPreguntas",
          {
            detaPreguntas: dataFinal,
          }

        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setCuestionario", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });

    },


    async getCedula({ commit, state }, cedulaVin) {
      //let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          "?cedulaVin=" + cedulaVin,
          //"https://localhost:44397/api/Cifin/?cedulaVin=" + cedulaVin,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setUsuario", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async verificarInformacion({ commit, state }, asesora) {
      state.cedulaAsesora = asesora.cedula
      state.celularAsesora = asesora.celular
      state.fechaNacimiento = asesora.fechaNacimiento
      state.fechaExpedicion = asesora.fechaExpedicion

      //console.log('verificarInformacion')

      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?cedulaVin=" +
          asesora.cedula +
          "&fechaExpedicionVin=" +
          asesora.fechaExpedicion +
          "&fechaNacimientoVin=" +
          asesora.fechaNacimiento +
          "&nroCelularVin=" +
          asesora.celular +
          "&primerApellioVin=" +
          asesora.primerApellido.toUpperCase(),
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultOTP", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });

    },

    async envioSMS({ commit, state }, codigoSMSdigitado) {
      //let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?ApplicationId=" +
          state.idTransaccion +
          "&codigoOTPE=" +
          codigoSMSdigitado,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultAsesora", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async reEnvioSMS({ commit, state }) {
      //let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?reEnvioApplicationId=" +
          state.idTransaccion +
          "&nroCelularVinRe=" +
          state.celularAsesora
          ,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          /*console.log('setResultReenvioOTP');
          console.log(data);*/
          commit("setResultReenvioOTP", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },


    async reEnvioCustionario({ commit, state }) {
      //let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?reEnvioApplicationIdCuestionario=" +
          state.idTransaccion,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          commit("setResultOTP", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },



    async envioDatos({ commit, state }, datosForm) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      state.asesora.primerNombre
      state.asesora.SegundoNombre

      state.datosFormAnt = datosForm

      if (state.nombreAsesora != '' && state.asesora.primerNombre == '') {
        state.asesora.primerNombre = state.nombreAsesora;
        //state.asesora.primerApellido = '.';
      }

      //console.log('DATOS DEL FORMULARIO')
      //console.log( datosForm)

      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?ApplicationIdBase64V2=" +
          state.idTransaccion +
          "&primerNombreV2=" +
          state.asesora.primerNombre +
          "&segundoNombreV2=" +
          state.asesora.SegundoNombre +
          "&primerApellidoV2=" +
          state.asesora.primerApellido +
          "&segundoApellidoV2=" +
          state.asesora.SegundoApellido +
          "&cedulaV2=" +
          state.cedulaAsesora +
          "&usuarioV2=" +
          usuario +
          "&reingresoV2=" +
          state.mensajeReingreso +
          "&ctrlVinculacionOtpManualV2=" +
          state.ctrlVinculacionOtpManual +
          "&datosV2",
          {
            datos: datosForm,
          }

        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultFinal", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },


    async envioDatosAnticipado({ commit, state }, datosForm) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");

      state.datosFormAnt = datosForm

      state.asesora.primerNombre = state.datosFormAnt.nombre
      state.asesora.primerApellido = state.datosFormAnt.apellido

      await axios
        .post(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?Ancontrol=" +
          state.idTransaccion +
          "&AnprimerNombreg=" +
          state.asesora.primerNombre +
          "&AnsegundoNombreg=" +
          state.asesora.SegundoNombre +
          "&AnprimerApellidog=" +
          state.asesora.primerApellido +
          "&AnsegundoApellidog=" +
          state.asesora.SegundoApellido +
          "&Ancedulag=" +
          state.cedulaAsesora +
          "&Anusuariog=" +
          usuario +
          "&Andatosg",
          {
            datos: state.datosFormAnt,
          }

        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultFinal", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },


    async validarCedula({ commit, state }, asesora) {
      let usuario = localStorage.getItem("usuario");
      let token = localStorage.getItem("token");

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?cedulaValidar=" +
          asesora.cedula +
          "&usuario=" +
          usuario,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setCedulaValidar", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async getIdZona({ commit, state }, cedulaVin) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/?usuarioZona=" + usuario,
          "?usuarioZona=" + usuario,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data.numCodigoZonas
          //console.log(data);
          commit("setIdZona", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async getDepartamentos({ commit, state }, idZona) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/?idCodigoZonaVin=" + idZona,
          "?idCodigoZonaVin=" + idZona,
        )
        .then((response) => {
          commit("setDepartamentos", response.data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async getCiudades({ commit, state }, idCiudad) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?idCodigoCiudadVin=" + idCiudad +
          "&usuarioVin=" +
          usuario
          ,
        )
        .then((response) => {
          //console.log(response);
          //let data = response.data.numCodigoZonas
          commit("setCiudades", response.data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    async getPoblados({ commit, state }, parametro) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?idCodigoDepartamentoVin=" +
          parametro.idDepartamento +
          "&idCodgoCiudadVin=" +
          parametro.idCiudad
          ,
        )
        .then((response) => {
          //console.log(response);
          //let data = response.data.numCodigoZonas
          commit("setPoblados", response.data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

    getDireccionCompleta({ commit, state }, parametro) {
      commit("setDireccionCompleta", parametro);
    },

    getDireccionCompletaEntrega({ commit, state }, parametro) {
      commit("setDireccionCompletaEntrega", parametro);
    },

    async getNomenclatura({ commit, state }) {
      let usuario = localStorage.getItem("usuario");
      //let token = localStorage.getItem("token");
      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/?idNomenclaturaVin=" +
          "?idNomenclaturaVin=" + "",
        )
        .then((response) => {
          //console.log(response);
          //let data = response.data.numCodigoZonas
          commit("setNomenclatura", response.data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },



    async consultaCedulaCifin({ commit, state }, asesora) {
      let usuario = localStorage.getItem("usuario");
      let token = localStorage.getItem("token");
      let checkAux = 1

      state.cedulaAsesora = asesora.cedula

      await axios
        .get(
          "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
          //"https://localhost:44397/api/Cifin/" +
          "?idCedulaCifinV2=" +
          asesora.cedula +
          "&IdcheckCifinV2=" +
          checkAux +
          "&IdusuarioCifinV2=" +
          usuario,
        )
        .then((response) => {
          //console.log(response);
          let data = response.data
          //console.log(data);
          commit("setResultCifinAnticipado", data);
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    },

  },
  modules: {
    login
  }
})
