<template>
  <div class="home" v-if="!mostrar">
    <div class="mx-4 bg-gray-50">
      <div class="my-4 bg-blue-900">
        <button
          class="relative flex justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
        >
          .
        </button>
      </div>

      <input type="hidden" name="remember" value="true" />

      <div class="rounded-md shadow-sm -space-y-px flex flex-col">
        <div class="ml-8">
          <h1 class="text-xl mb-2 text-red-900">Verificación de Datos..</h1>
          <h1 class="text-xl mb-2 text-red-700 text-center">
            {{ mensajeReingresoAux }}  {{ resultEvaluacionRealAux }}
          </h1>
        </div>

        <div class="md:flex mx-8">
          <div class="md:w-1/2 mr-2">
            <label for="cedula" class="">Cedula *</label>
            <input
              :disabled="disabled == 1"
              id="cedula"
              name="cedula"
              type="number"
              required
              @focus="CedulaFocus()"
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Cedula"
              v-model="asesora.cedula"
              ref="cedulaInput"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="FechaExpedicion" class="mt-8">Fecha Expedición *</label>
            <input
              id="FechaExpedicion"
              name="FechaExpedicion"
              type="Date"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
              placeholder="Fecha Expedicion"
              v-model="asesora.fechaExpedicion"
              @focus="verificarCedula()"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="FechaNacimiento" class="">Fecha Nacimiento *</label>
            <input
              id="FechaNacimiento"
              name="FechaNacimiento"
              type="Date"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Fecha Nacimiento"
              v-model="asesora.fechaNacimiento"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="primerApellido" class="">Nro.Celular *</label>
            <input
              id="nro.Celular"
              name="nro.Celular"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Nro.Celular"
              v-model="asesora.celular"
            />
          </div>

          <div class="md:w-1/2 mr-2">
            <label for="primerApellido" class="">Primer Apellido *</label>
            <input
              id="primerApellido"
              name="primerApellido"
              type="text"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Primer Apellido"
              v-model="asesora.primerApellido"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col mx-8">
        <div class="flex items-start">
          <input
            :disabled="checkedDisable"
            v-model="checked"
            id="IdTerCond"
            name="IdTerCond"
            type="checkbox"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <button
            @click.prevent="cerrarTerminos"
            class="ml-2 block text-sm text-gray-900"
          >
            <u> Términos y condiciones.</u>
          </button>
        </div>

        <div class="flex items-start">
          <input
            v-model="checkedp"
            id="remember-mep"
            name="remember-mep"
            type="checkbox"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <button
            @click.prevent="cerrarModalPagareContrato"
            class="ml-2 block text-sm text-gray-900"
          >
            <u> Pagaré y Contrato.</u>
          </button>
        </div>

        <!--<div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>-->
      </div>

      <div>
        <button
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          @click.prevent="verificarInfo()"
          :disabled="buttonIsActivo"
        >
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg
              class="h-5 w-5 text-indigo-500 group-hover:text-pink-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          Verificar Información
        </button>
      </div>

      <modalProps
        v-show="mostrarModal"
        :titulo="tituloM"
        :msg1="mensajeM"
        :tipos="tipoM"
      ></modalProps>
      <noticemodal v-show="terminosModal"></noticemodal>
      <modalConfirmacion v-show="modalConf"></modalConfirmacion>
      <modalLoading v-show="modalLoad"></modalLoading>
      <modalPagareContrato v-show="modalPagCont"></modalPagareContrato>
      <modalPagAnticipado v-show="modalPagoAnt"></modalPagAnticipado>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import { computed, watchEffect, inject, onMounted } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import modalProps from "../components/modalPropos.vue";
import noticemodal from "../components/noticemodal.vue";
import modalConfirmacion from "../components/modalconfirmacion.vue";
import modalPagareContrato from "../components/pagareContrato.vue";
import modalLoading from "../components/modalLoading.vue";
import modalPagAnticipado from "../components/modalPagoAnticipado.vue";
import router from "../router/index.js";
import { async } from "q";
/*
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {useLoading} from 'vue-loading-overlay'
*/

export default {
  //name: "Home",
  components: {
    modalProps,
    noticemodal,
    modalConfirmacion,
    modalLoading,
    modalPagareContrato,
    modalPagAnticipado,
  },

  setup() {
    //const $loading = useLoading();
    //const fullPage = ref(false)
    //const swal = inject('$swal')

    const store = useStore();
    const disabled = ref(0);
    const tituloM = ref("");
    const mensajeM = ref("");
    const tipoM = ref("");
    const buttonIsActivo = ref(true);
    const mensajeReingresoAux = ref("");
    const resultGrabar = computed(() => store.state.result);
    const resultCedula = computed(() => store.state.resultGetCedula);
    const notaAux = computed(() => store.state.nota);
    const lstPreguntas = computed(() => store.state.preguntas);
    const codigoOTP = computed(() => store.state.codigoOTP);
    const mensaReingreso = computed(() => store.state.mensajeReingreso);
    const estadoConsulta = computed(() => store.state.resultCifin);
    const resultEvaluacionRealAux = computed(() => store.state.resultEvaluacionReal);
    
    const estadoConsultaAnticipado = computed(
      () => store.state.resultCifinAnticipado
    );

    const colorTempo = ref("");
    const controlText = ref("alonso");

    const mostrarModal = computed(() => store.state.mostrarModal);
    watchEffect(() => {
      mostrarModal.value = computed(() => store.state.mostrarModal);
    });

    const terminosModal = computed(() => store.state.teminosModalV);
    watchEffect(() => {
      terminosModal.value = computed(() => Store.state.teminosModalV);
    });

    const modalConf = computed(() => store.state.ModalV);
    watchEffect(() => {
      modalConf.value = computed(() => Store.state.ModalV);
    });

    const modalLoad = computed(() => store.state.mostrarModalLoading);
    watchEffect(() => {
      modalLoad.value = computed(() => Store.state.mostrarModalLoading);
    });

    const modalPagCont = computed(() => store.state.pagareContratoModal);
    watchEffect(() => {
      modalPagCont.value = computed(() => Store.state.pagareContratoModal);
    });

    const modalPagoAnt = computed(() => store.state.ModalPagoAnticipado);
    watchEffect(() => {
      modalPagoAnt.value = computed(() => Store.state.ModalPagoAnticipado);
    });

    const cerrarmodal = () => {
      store.commit("closeModal");
    };

    const cerrarmodalLoading = () => {
      store.commit("closeModalLoading");
    };

    const cerrarTerminos = () => {
      store.commit("terminosModal");
    };

    const cerrarModalConf = () => {
      store.commit("ModalCodigo");
    };

    const cerrarModalPagoAnticipado = () => {
      store.commit("ModalPagoAnticipado");
    };

    const cerrarModalPagareContrato = () => {
      store.commit("closeModalPagareContato");
    };

    const asesora = ref({
      cedula: "",
      fechaExpedicion: "",
      fechaNacimiento: "",
      celular: "",
      primerApellido: "",
    });

    const checked = ref(false);
    const checkedp = ref(false);

    const checkedDisable = ref(false);

    const verificarInfo = async () => {
      if (
        asesora.value.cedula == undefined ||
        asesora.value.cedula == "" ||
        asesora.value.fechaExpedicion == undefined ||
        asesora.value.fechaExpedicion == "" ||
        asesora.value.fechaNacimiento == undefined ||
        asesora.value.fechaNacimiento == "" ||
        asesora.value.celular == undefined ||
        asesora.value.celular == "" ||
        asesora.value.primerApellido == undefined ||
        asesora.value.primerApellido == ""
      ) {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Los campos con * deben ser diligenciados",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        return;
      }

      if (checked.value != 1) {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Debes aceptar los Terminos y Condiciones",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        return;
      }

      if (checkedp.value != 1) {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Debes aceptar el pagaré y el contrato",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        return;
      }

      cerrarmodalLoading();

      await store.dispatch("verificarInformacion", asesora.value);

      if (
        lstPreguntas.value.controlMensaje.substring(0, 3) == "OTP" &&
        (codigoOTP.value != "" || codigoOTP != "undefined")
      ) {
        cerrarmodalLoading();
        cerrarModalConf();
      } else if (lstPreguntas.value.controlMensaje.trim() == "EXAMDATA") {
        cerrarmodalLoading();
        router.push("/question");
      } else {
        cerrarmodalLoading();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: lstPreguntas.value.controlMensaje,
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        buttonIsActivo.value = true;
      }

      /*if (resultGrabar.value == "OK") {
        tituloM.value = "Confirmación";
        mensajeM.value = "Datos Registrado con éxito";
        tipoM.value = "0";
        cerrarmodal();
      } else if (resultGrabar.value == "EXISTE") {
        tituloM.value = "Oops";
        mensajeM.value = "La asesora se encuentra registrada";
        tipoM.value = "2";
        cerrarmodal();
      } else if (resultGrabar.value == "SINTOKEN") {
        router.push("/login");
      } else {
        tituloM.value = "Oops";
        mensajeM.value = "Datos no registrados, Comunicarse con Técnologia";
        tipoM.value = "1";
        cerrarmodal();
      }*/
    };

    const pasarPantalla = () => {
      //alertify.alert('No se creo el registro', 'Comuniquese con el area de tecnologia!');
      //swal('Something went wrong.')
      //router.push("/question");
      router.push("/talonso");
      /* Swal.fire({
        title: 'Error!',
        text: 'Do you want to continue',
        icon: 'error',
        confirmButtonText: 'Cool'
      })*/
    };

    const mostrar = computed(() => {
      return store.state.login.mostrar;
    });

    const getCedula = async (parametro) => {
      cerrarmodalLoading();
      await store.dispatch("getCedula", parametro);

      if (resultCedula.value.cedula === "") {
        console.log("");
      } else {
        cerrarmodalLoading();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "La asesora se encuentra registrada",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      }
    };

    const verificarCedula = async () => {
      if (checked.value != 1) {
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Debes aceptar los Terminos y Condiciones",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
        return;
      }

      checkedDisable.value = true;

      if (asesora.value.cedula == undefined || asesora.value.cedula == "") {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Favor Ingresar Primero la cédula ",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        asesora.value.fechaExpedicion = "";
        document.getElementById("cedula").focus();
        return;
      }

      cerrarmodalLoading();

      await store.dispatch("validarCedula", asesora.value);

      mensajeReingresoAux.value = "";

      console.log("notaAux.value");
      console.log(notaAux.value.trim());

      if (
        notaAux.value.trim() == "DCCMAYOR" ||
        notaAux.value.trim() == "MAY84R"
      ) {
        document.getElementById("cedula").focus();
        buttonIsActivo.value = true;

        cerrarmodalLoading();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora no aprobada, presentó o presenta mora con la compañia, comunicarse con cartera",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (
        notaAux.value.trim() == "DCCMENOR" ||
        notaAux.value.trim() == "MAY84"
      ) {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora comunicarse con cartera para continuar con el proceso",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (notaAux.value.trim() == "EXISTE") {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora Existe en nuestra base de datos",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (notaAux.value.trim() == "BLOQUEOD") {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora bloqueada por un DIA",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (notaAux.value.trim() == "BLOQUEOM") {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora bloqueada por un MES",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (notaAux.value.trim() == "BLOQUEOS") {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Asesora bloqueada por una SEMANA",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (
        notaAux.value.trim() == "MEN84" ||
        notaAux.value.trim() == "APROBADO"
      ) {
        cerrarmodalLoading();

        buttonIsActivo.value = true;
        document.getElementById("cedula").focus();

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Reingreso Aprobado, verificado",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (
        notaAux.value.trim() == "MINIMO4CAMP" ||
        notaAux.value.trim() == "NUEVANOVERIFICADA" ||
        notaAux.value.trim() == "REINCORPORACION"
      ) {
        cerrarmodalLoading();

        buttonIsActivo.value = false;

        GetConsultaCifin();

        if (notaAux.value.trim() == "REINGRESO") {
          mensajeReingresoAux.value = "Reingreso";
        }

        if (notaAux.value.trim() == "MINIMO4CAMP") {
          mensajeReingresoAux.value = "Reingreso";
        }

        if (notaAux.value.trim() == "NUEVANOVERIFICADA") {
          mensajeReingresoAux.value = "Nueva CA";
        }

        if (notaAux.value.trim() == "REINCORPORACION") {
          mensajeReingresoAux.value = "Reincorporación";
        }

        //document.getElementById("cedula").focus();
      } else if (notaAux.value.trim() == "NUEVAVERIFICADA") {
        document.getElementById("cedula").focus();

        cerrarmodalLoading();

        buttonIsActivo.value = true;

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Nueva campaña anterior Verificada",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else if (notaAux.value.trim() == "ENPROCESO") {
        document.getElementById("cedula").focus();

        cerrarmodalLoading();

        buttonIsActivo.value = true;

        Swal.fire({
          title: "Mensaje del sistema!",
          text: "Nueva con proceso de vinculación pendiente",
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      } else {
        cerrarmodalLoading();

        GetConsultaCifin();

        /*Se valida el estado en las centrales de riesgo*/
        /*Fin Se valida el estado en las centrales de riesgo*/
      }

      //store.commit("closeModal");
    };

    const mod = async () => {
      tituloM.value = "Oops";
      mensajeM.value = "loading";
      tipoM.value = "2";
      cerrarmodalLoading();

      setTimeout(function () {
        console.log("Hola Mundo");
      }, 5000);

      store.commit("closeModalLoadingAux");
    };

    const pruebaAux = () => {
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    };

    const colorAux = () => {
      colorTempo.value = "green";
      controlText.value = "castro";
    };

    const limpiarForm = () => {
      asesora.value.cedula = "";
      asesora.value.fechaExpedicion = "";
      asesora.value.fechaNacimiento = "";
      asesora.value.celular = "";
      asesora.value.primerApellido = "";
    };

    const CedulaFocus = () => {
      checkedDisable.value = false;
      checked.value = false;
    };

    const pasarPantallaT = () => {
      router.push("/Talonso");
    };


    const GetConsultaCifin = async () => {
      /*Se valida el estado en las centrales de riesgo*/
      await store.dispatch("consultaCedulaCifin", asesora.value);
      let estado = estadoConsultaAnticipado.value;

      console.log("ESTADO CIFIN-anticipado");
      console.log(estado.mensajeEvaluacion);

      if (estado.mensajeEvaluacion == "APROBADO") {
        buttonIsActivo.value = false;
        return;
      } else {
        buttonIsActivo.value = true;
        //document.getElementById("cedula").focus();
        checkedDisable.value = false;
        //checked.value = false

        Swal.fire({
          title: "Mensaje del Sistema",
          text: "Asesora Rechazada, Desea trabajar Pago Anticipado",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            /*Swal.fire(
                  'Registro!',
                  'Registro Ingresado',
                  'success'
                )*/
            router.push("/anticipado");
          } else {
             store.commit("LimpiarVariables");
             router.push("/");
            document.getElementById("cedula").focus();
          }
        });
      }
      /*Fin Se valida el estado en las centrales de riesgo*/
    };

    onMounted(() => limpiarForm());

    return {
      mostrar,
      asesora,
      checked,
      tituloM,
      mensajeM,
      tipoM,
      mostrarModal,
      resultGrabar,
      terminosModal,
      cerrarTerminos,
      pasarPantalla,
      cerrarModalConf,
      modalConf,
      getCedula,
      resultCedula,
      verificarInfo,
      verificarCedula,
      notaAux,
      buttonIsActivo,
      codigoOTP,
      cerrarmodalLoading,
      modalLoad,
      mod,
      checkedp,
      modalPagCont,
      cerrarModalPagareContrato,
      modalPagoAnt,
      cerrarModalPagoAnticipado,
      mensajeReingresoAux,
      mensaReingreso,
      disabled,
      pruebaAux,
      colorAux,
      colorTempo,
      controlText,
      limpiarForm,
      estadoConsulta,
      estadoConsultaAnticipado,
      checkedDisable,
      CedulaFocus,
      GetConsultaCifin,
      pasarPantallaT,
      resultEvaluacionRealAux,
      
    };
  },
};
</script>
  