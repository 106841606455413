<template>
  <div
    :class="fondoclass"
    class="h-4/5 "
  >
    <div
      :class="classModal"
      class="h-3/5"
    >
      <div class="
          shadow-sm
          flex
          justify-center
          w-8
          h-8
          border-2 border-red-600
          rounded
          text-center
        ">
        <button @click="cerrarModalPagoAnticipado">
          <p class="font-bold tect-red-500">x</p>
        </button>
      </div>
     

      <div class="overflow-auto h-full text-justify">
        <div class="grid justify-items-center ">
          <img
            :src="img"
            alt=""
          />
        </div>

        <div class="grid justify-items-center">

          <p class="text-2xl my-2">Asesor(a), su solictiud a sido RECHAZADA, desea trabajar en modalidad de pago anticipado ? </p>
          <!--<input
            id="Idcodigopa"
            name="Idcodigopa"
            type="text"
            required
            class="
                appearance-none
                rounded-none
                relative
                block
                
                px-3
                py-1
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                my-2
                text-2xl
                text-center
                
              "
            placeholder="Código"
          />-->

         <div class="grid grid-cols-4 gap-4">
            <div></div>
            <div> <button
            class="
                    
                    relative
                    mt-2
                    flex
                    justify-right
                    py-2
                    px-4
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-pink-800
                    hover:bg-pink-700
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
                  "
            @click.prevent="enviarSi()"
          >Si Acepto</button></div>
            <div> <button
            class="
                    
                    relative
                    mt-2
                    flex
                    justify-left
                    py-2
                    px-4
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-pink-800
                    hover:bg-pink-700
                    focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-pink-500
                  "
            @click.prevent="enviarNo()"
          >No Acepto</button></div>
            <div></div>
        </div>

        <div class="grid grid-cols-3   gap-4">
             <div></div>
             <div><label for="" class="text-center text-2xl text-red-600/10">{{mensajeM}} </label></div>
             <div></div> 
        </div>

        </div>
        <!--<div class="grid justify-items-center">
          <img :src="img" alt="" />
        </div>-->
      </div>
      <modalProps
          v-show="mostrarModal"
          :titulo="tituloM"
          :msg1="mensajeM"
          :tipos="tipoM"
        ></modalProps>
      <modalLoading v-show="modalLoad"></modalLoading>
      </div>
      </div>
</template>
<script>
import img from "../assets/logos/LogoJuana.png";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, watchEffect } from "vue";
import router from "../router/index.js";
import modalLoading from "../components/modalLoading.vue";
import modalProps from "../components/modalPropos.vue";
import { async } from 'q';

export default {
  name: "noticeModalPagAnt",
  components: {
    modalLoading,
    modalProps
  },

  setup() {
    const store = useStore();
    const asesoraAux = computed(() => store.state.asesora);
    const mensajeFinal = computed(() => store.state.respuestaGrabar);
    const tituloM = ref("");
    const mensajeM = ref("");
    const tipoM = ref("");

     const modalLoad = computed(() => store.state.mostrarModalLoading);
    watchEffect(() => {
      modalLoad.value = computed(() => Store.state.mostrarModalLoading);
    });

      const modalPagoAnt = computed(() => store.state.ModalPagoAnticipado);
    watchEffect(() => {
      modalPagoAnt.value = computed(() => Store.state.ModalPagoAnticipado);
    });

    const mostrarModal = computed(() => store.state.mostrarModal);
    watchEffect(() => {
      mostrarModal.value = computed(() => store.state.mostrarModal);
    });


    const cerrarmodal = () => {
      store.commit("closeModal");
    };

    const cerrarmodalLoading = () => {
      store.commit("closeModalLoading");
    };
    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center   relative mx-2 z-50  px-3 py-2 flex items-center justify-center rounded absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-8 lg:mt-10"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch p-3 left-0 top-0 bottom-0 right-0  fixed bg-opacity-50 bg-gray-900 "
    );

    const cerrarModalPagoAnticipado = () => {
      store.commit("ModalPagoAnticipado");
    };

   /* const enviarSMS = async () => {

      cerrarmodalLoading();

      await store.dispatch("envioSMS");

      if (asesoraAux.value.controlMensaje == "DCTOS") {
        cerrarmodalLoading();
        cerrarModalPagoAnticipado();
        router.push("/about");
      } else {
        cerrarmodalLoading();
        tituloM.value = "Oops";
        mensajeM.value = "Problema en el envio del SMS";
        tipoM.value = "2";
        cerrarModalPagoAnticipado();
      }
    };*/

    const enviarNo = () => {
        cerrarModalPagoAnticipado()
        router.push("/");
    };

    const enviarSi = async () => {

      cerrarmodalLoading();

      await store.dispatch("envioDatosAnticipado");

       if(mensajeFinal.value == 'APROBADO'){
        cerrarmodalLoading();
        //tituloM.value = "Oops";
        //mensajeM.value = "Proceso Finalizado con éxito";
        //tipoM.value = "0";
        //cerrarModalPagoAnticipado()

        //tituloM.value = "Oops";
        //mensajeM.value = "Proceso Finalizado con éxito";
        //tipoM.value = "1";
        //cerrarmodal();
        //return;
      
        //router.push("/");

         Swal.fire({
          title: 'Mensaje del sistema!',
          text: "Proceso Finalizado con éxito",
          icon: 'success',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#F3566E'
         })

         router.push("/")

       }else {
         cerrarmodalLoading();
          //tituloM.value = "Oops";
          //mensajeM.value = mensajeFinal.value
          //tipoM.value = "2";
          //cerrarModalPagoAnticipado();
          //return;

          Swal.fire({
          title: 'Mensaje del sistema!',
          text: mensajeFinal.value,
          icon: 'success',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#F3566E'
         })
       }  

    };




    return {
      classModal,
      fondoclass,
      img,
      asesoraAux,
      cerrarmodalLoading,
      modalLoad,
      modalPagoAnt,
      cerrarModalPagoAnticipado,
      enviarNo,
      enviarSi,
       tituloM,
      mensajeM,
      tipoM,
      mostrarModal
    };
  }
};
</script>

<style>
</style>