<template>
  <div :class="fondoclass" class="">
    <div :class="classModal" class="h-4/5">
      <div
        class="
          shadow-sm
          flex
          justify-center
          w-8
          h-8
          border-2 border-red-600
          rounded
          text-center
        "
      >
        <button @click="cerrarTerminos">
          <p class="font-bold tect-red-500">x</p>
        </button>
      </div>
      <hr />

      <div class="overflow-auto h-full text-justify">
        <div class="grid justify-items-center ">
          <img :src="img" alt="" />
        </div>
        <p class="font-bold">FLOR’ES ELEGANCIA INTIMA S.A.S.</p>
        <p class="font-bold">
          POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN FLOR’ES ELEGANCIA INTIMA
          S.A.S
        </p>
        <br />

        <p class="font-bold">Compañía: FLOR’ES ELEGANCIA INTIMA S.A.S.</p>
        <p>Nit: 900365409-1</p>
        <p class="font-bold">Dirección:</p>
        Calle 57 Sur 48-24 interior 103-104 Sabaneta-Antioquia.
        <br />
        <p class="font-bold">TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS:</p>
        <p>
          La información suministrada por el titular será incluida en nuestra
          base de datos y será utilizada para: Proporcionar nuestros servicios y
          productos Llamada de verificación al titular, al codeudor y a las
          referencias para corroborar la veracidad de la información. Consulta
          de la vida crediticia del deudor y del codeudor en Centrales de riesgo
          para aprobación de crédito. Información suministrada a los proveedores
          que nos prestan servicios relacionados con la misión de la empresa.
          Envió por cualquiera de los medios de comunicación existentes
          información sobre todo evento comercial que se considere importante
          para las partes.

          FLOR’ES ELEGANCIA ÍNTIMA S.A.S.  en los casos en que medie autorización del usuario 
          podrá realizar contacto a través de los siguientes canales: Llamada telefónica, mensaje de texto, WhatsApp, correo electrónico.
          A su vez los usuarios serán informados de forma clara y expresa acerca de las herramientas puestas a disposición para modificar, 
          personalizar y/o retirar la autorización para el uso de dichos canales.
        </p>
        <br />
        <p class="font-bold">FLOR’ES ELEGANCIA INTIMA S.A.S.</p>
        <p>
          Como responsable de las bases de datos privadas las cuales serán
          administradas de forma manual y/o sistemática que utiliza para sus
          efectos comerciales y laborales, declara a sus grupos de interés su
          Política de Tratamiento Datos Personales, la cual se desarrollará a
          través de la norma que da cumplimiento al régimen de protección de
          datos personales, consagrado en ley 1581 de 2012 y que estarán
          permanentemente disponibles en la página www.juanabonita.com.co. Los
          datos personales que custodia FLOR’ES ELEGANCIA INTIMA S.A.S., en su
          condición de responsable y/o encargado, serán tratados cumpliendo los
          principios y regulaciones previstas en las leyes colombianas
          aplicables, las cuales están alineadas con las buenas prácticas
          internacionales en la materia. Tales datos personales serán tratados
          por FLOR’ES ELEGANCIA INTIMA S.A.S. conforme las siguientes
          finalidades de carácter general:
          <br />

          Clientes: Para el cumplimiento de las obligaciones derivadas de las
          relaciones contractuales existentes con sus clientes activos o
          inactivos, de acuerdo con los lineamientos que determinan las leyes o
          decretos que regulen la materia.  Empleados: Para el cumplimiento de
          las obligaciones derivadas de las relaciones contractuales existentes
          con sus empleados y ex empleados, de acuerdo con los lineamientos que
          determinan las leyes o decretos que regulen la materia.  Proveedores
          para el cumplimiento de las obligaciones legales que involucren datos
          personales con sus proveedores que tenga o haya tenido relaciones
          comerciales de acuerdo con los lineamientos que determinan las leyes o
          decretos que regulen la materia.  En cada proceso empresarial, los
          datos serán administrados conforme se haya informado a cada uno de sus
          actores con acogimiento a la ley de privacidad se informarán de forma
          previa las finalidades particulares, y si el tratamiento se efectuará
          por un encargado del mismo bajo la dirección de FLOR’ES ELEGANCIA
          INTIMA S.A.S., certificamos que hemos constatado que el tratamiento
          dado por el encargado cuenta igualmente con las condiciones de
          seguridad y privacidad garantizadas por el responsable.
        </p>
        <br />

        <p>
          Cualquier persona natural o jurídica que haga parte de uno de los
          grupos antes mencionado en relación con tratamiento de sus datos
          personales tiene derecho a:
        </p>
        <br />
        <p>
          1. A Ejercer su derecho de habeas data consistente en conocer,
          actualizar, rectificar, oponerse y cancelar las informaciones
          recogidas de ellas, en aquellos casos en que ello proceda. 2.
          Evidenciar la existencia del consentimiento otorgado. 3. Ejercer las
          acciones que la ley colombiana dispone dentro del marco jurídico que
          rige la materia de administración y privacidad de bases de datos.
        </p>
        <br /><br />
        <p>
          Para el ejercicio del habeas data, el titular del dato personal o su
          causahabientes que demuestre un legítimo interés conforme lo señalado
          en la normatividad vigente, podrá hacerlo a través del correo
          electrónico: contacto@juanabonita.com.co o dirigiendo una comunicación
          a la siguiente dirección física: Calle 57 Sur 48-24 interior 103-104
          Sabaneta, Antioquia. Para suministrar la información requerida
          amparada en el derecho de la consulta consagrada en la ley de Habeas
          Data, el solicitante debe suministrar con <br />
        </p>
        <p>
          veracidad los datos donde podrá ser contactado en virtud de poder
          entregar y dar trámite a la solicitud. Recibida la solicitud de
          ejercicio de Habeas Data, FLOR’ES ELEGANCIA INTIMA S.A.S dará
          respuesta a las consultas y/o reclamaciones conforme lo indicado en
          los artículos 14 y 15 de la Ley 1581 de 2012. El tratamiento de los
          datos personales que realiza FLOR’ES ELEGANCIA INTIMA S.A.S. se hará
          conforme a la normatividad interna que se adopte para el cumplimiento
          de la legislación aplicable. Esta política ha sido aprobada por el
          representante legal de FLOR’ES ELEGANCIA INTIMA S.A.S. El veinte (14)
          de septiembre de 2020
        </p>
        <br />

        <p class="font-bold">
          POLÍTICA DE TRATAMIENTO DE PROTECCIÓN DE DATOS PERSONALES DE LOS
          TITULARES FLOR’ES ELEGANCIA INTIMA S.A.S.
        </p>
        <br />
        <p class="">
          Dando cumplimiento a lo dispuesto en la Ley estatutaria 1581 de 2012 y
          a su Decreto Reglamentario 1377 de 2013 y demás normas vigentes,
          FLOR’ES ELEGANCIA INTIMA S.A.S adopta la presente política para el
          tratamiento de datos personales, la cual será informada a todos los
          titulares de los datos recolectados actualmente y los que recolecte a
          futuro se obtengan en el ejercicio de las actividades comerciales y/o
          laborales, De esta manera, FLOR’ES ELEGANCIA INTIMA S.A.S manifiesta
          que garantiza los derechos de la privacidad, la intimidad, el buen
          nombre, en el tratamiento de los datos personales, y en consecuencia
          todas sus actuaciones se regirán por los principios de legalidad,
          finalidad, libertad, veracidad, calidad, transparencia, acceso,
          circulación restringida, seguridad y confidencialidad. Todas las
          personas naturales o jurídicas que en desarrollo actividades
          comerciales y/o laborales, entre otras, sean permanentes u
          ocasionales, llegarán a suministrar a FLOR’ES ELEGANCIA INTIMA S.A.S
          cualquier tipo de información o dato personal, podrá conocerla,
          actualizarla, rectificarla y/o suprimirla
        </p>
        <br />
        <br />
        <p>
          I. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO Que en adelante se
          denominará FLOR’ES ELEGANCIA INTIMA S.A.S, en virtud de su condición
          de confeccionador y comercializador de prendas de vestir femenina y
          masculina DOMICILIO Y DIRECCIÓN: FLOR’ES ELEGANCIA INTIMA S.A.S tiene
          su domicilio en la ciudad de Sabaneta, en la dirección calle 57 sur #
          48 – 24 int 103 - 104. Correo electrónico contacto@juanabonita.com.co
          teléfono: 448 76 96. II. MARCO LEGAL Constitución Política, artículo
          15. Ley 1266 de 2008 Ley 1581 de 2012 Decretos Reglamentarios 1727 de
          2009 y 2952 de 2010, Decreto Reglamentario parcial 1377 de 2013
          Sentencias C – 1011 de 2008, y C - 748 del 2011, de la Corte
          Constitucional III. ÁMBITO DE APLICACIÓN La presente política será
          aplicable a los datos personales registrados en cualquier base de
          datos de FLOR’ES ELEGANCIA INTIMA S.A.S cuyo titular sea una persona
          natural o jurídica, de naturaleza pública o privada.
        </p>
        <br />
        <br />
        <p>
          IV. DEFINICIONES Para los efectos de la presente política y en
          concordancia con la normatividad vigente en materia de protección de
          datos personales, se tendrán en cuenta las siguientes definiciones:
          Autorización: aceptación expresa otorgada por el titular una vez
          conocido el tratamiento que se le dará a los datos personales
          suministrados Tratamiento: al autorizar el manejo de los datos el
          titular permite: que consultemos los datos en centrales de riesgo, así
          mismo que los suministremos a empresas que nos presten servicios
          acordes con nuestro objeto social y con el objetivo único de cumplir
          con nuestro objeto social como: agencias de cobranza, centrales de
          riesgo, operadores de recaudo, trasportadores y todas aquellas que
          sean necesarias para el normal desarrollo de nuestra relación
          comercial. Aviso de privacidad: Comunicación verbal o escrita generada
          por el Responsable, dirigida al Titular para el tratamiento de sus
          datos personales, mediante la cual se le informa acerca de la
          existencia de las políticas de tratamiento de información que le serán
          aplicables, la forma de acceder a las mismas y las finalidades del
          tratamiento que se pretende dar a los datos personales. Base de Datos:
          Conjunto organizado de datos personales que sea objeto de tratamiento.
          Causahabiente: persona que ha sucedido a otra por causa del
          fallecimiento de ésta (heredero). Dato personal: Cualquier información
          vinculada o que pueda asociarse a una o varias personas naturales
          determinadas o determinable Dato público: Es el dato que no sea
          semiprivado, privado o sensible. Son considerados datos públicos,
          entre otros, los datos relativos al estado civil de las personas, a su
          profesión u oficio y a su calidad de comerciante o de servidor
          público. Por su naturaleza, los datos públicos pueden estar
          contenidos, entre otros, en registros públicos, documentos públicos,
          gacetas y boletines oficiales y sentencias judiciales debidamente
          ejecutoriadas que no estén sometidas a reserva. FLOR’ES ELEGANCIA
          INTIMA S.A.S. 900365409 – 1 Calle 57 sur # 48 – 24 C.I. METROSABANA /
          teléfono 448 76 96 Datos sensibles: Se entiende por datos sensibles
          aquellos que afectan la intimidad del Titular o cuyo uso indebido
          puede generar su discriminación, tales como que revelen el origen
          racial o étnico, la orientación política, las convicciones religiosas
          o filosóficas, la pertenencia a sindicatos, organizaciones sociales,
          de derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual, y los datos biométricos. Encargado del Tratamiento:
          Persona natural o jurídica, pública o privada, que para cumplimiento
          de la relación existente, realiza el Tratamiento de datos personales
          por cuenta del Responsable del Tratamiento. Responsable del
          Tratamiento: Persona natural o jurídica, pública o privada, que por sí
          misma o en asocio con otros, decida sobre la base de datos y/o el
          Tratamiento de los datos. Titular: Persona natural cuyos datos
          personales sean suministrados para iniciar cualquier tipo de relación
          con el Responsable y que ha conocido los derechos de privacidad de sus
          datos personales. Tratamiento: Cualquier operación o conjunto de
          operaciones sobre datos personales, tales como la recolección,
          almacenamiento, uso, circulación o supresión. Transferencia: la
          transferencia de datos tiene lugar cuando el responsable y/o encargado
          del tratamiento de datos personales, envía la información o los datos
          personales a un receptor, que a su vez es responsable del tratamiento
          y se encuentra dentro o fuera del país. Transmisión: tratamiento de
          datos personales que implica la comunicación de los mismos dentro o
          fuera del territorio de la República de Colombia cuando tenga por
          objeto la realización de un tratamiento por el encargado por cuenta
          del responsable, para el caso nuestro será transmitida a agencias de
          cobranza que nos garanticen que la política de privacidad será
          respetada y conservada acorde con las políticas nuestras. V.
          PRINCIPIOS Para efectos de garantizar la protección de datos
          personales, FLOR’ES ELEGANCIA INTIMA S.A.S aplicará de manera armónica
          e integral los siguientes principios, a la luz de los cuales se deberá
          realizar el tratamiento, transferencia y transmisión de datos
          personales. Principio de legalidad en materia de Tratamiento de datos:
          El tratamiento de datos es una actividad regulada, la cual deberá
          estar sujeta a las disposiciones legales vigentes y aplicables que
          rigen el tema. Principio de finalidad: la actividad del tratamiento de
          datos personales que realice FLOR’ES ELEGANCIA INTIMA S.A.S o a la
          cual tuviere acceso, obedecerán a una finalidad legítima en
          consonancia con la Constitución Política de Colombia, la cual deberá
          ser informada al respectivo titular de los datos personales.
        </p>
        <br />
        <p>
          Principio de libertad: el tratamiento de los datos personales sólo
          puede realizarse con el consentimiento, previo, expreso e informado
          del Titular. Los datos personales no podrán ser obtenidos o divulgados
          sin previa autorización, o en ausencia de mandato legal, estatutario,
          o judicial que releve el consentimiento. Principio de veracidad o
          calidad: la información sujeta a Tratamiento de datos personales debe
          ser veraz, completa, exacta, actualizada, comprobable y comprensible.
          Se prohíbe el Tratamiento de datos parciales, incompletos,
          fraccionados o que induzcan a error. Principio de transparencia: En el
          tratamiento de datos personales, FLOR’ES ELEGANCIA INTIMA S.A.S
          garantizará al Titular su derecho de obtener en cualquier momento y
          sin restricciones, información acerca de la existencia de cualquier
          tipo de información o dato personal que sea de su interés o
          titularidad. Principio de acceso y circulación restringida: El
          tratamiento de datos personales se sujeta a los límites que se derivan
          de la naturaleza de éstos, de las disposiciones de la ley y la
          Constitución. En consecuencia, el tratamiento sólo podrá hacerse por
          personas autorizadas por el titular y/o por las personas previstas en
          la ley. Los datos personales, salvo la información pública, no podrán
          estar disponibles en internet u otros medios de divulgación o
          comunicación masiva, salvo que el acceso sea técnicamente controlable
          para brindar un conocimiento restringido sólo a los titulares o
          terceros autorizados conforme a la ley. Para estos propósitos la
          obligación de FLOR’ES ELEGANCIA INTIMA S.A.S, será de medio. Principio
          de seguridad: la información sujeta a tratamiento por FLOR’ES
          ELEGANCIA INTIMA S.A.S, se deberá manejar con las medidas técnicas,
          humanas y administrativas que sean necesarias para otorgar seguridad a
          los registros evitando su adulteración, pérdida, consulta, uso o
          acceso no autorizado o fraudulento. Principio de confidencialidad:
          Todas las personas que en FLOR’ES ELEGANCIA INTIMA S.A.S, administren,
          manejen, actualicen o tengan acceso a informaciones de cualquier tipo
          que se encuentre en Bases de Datos, están obligadas a garantizar la
          reserva de la información, por lo que se comprometen a conservar y
          mantener de manera estrictamente confidencial y no revelar a terceros,
          toda la información que llegaren a conocer en la ejecución y ejercicio
          de sus funciones; salvo cuando se trate de actividades autorizadas
          expresamente por la ley de protección de datos. Esta obligación
          persiste y se mantendrá inclusive después de finalizada su relación
          con alguna de las labores que comprende el Tratamiento.
        </p>
        <p class="font-bold">VI. DERECHOS DEL TITULAR DE LA INFORMACIÓN</p>
        <br />
        <p>
          De acuerdo con lo contemplado por la normatividad vigente aplicable en
          materia de protección de datos, los siguientes son los derechos de los
          titulares. 1. A Acceder, conocer, actualizar y rectificar sus datos
          personales frente a FLOR’ES ELEGANCIA INTIMA S.A.S en su condición de
          responsable del tratamiento. Este derecho se podrá ejercer, entre
          otros, frente a datos parciales, inexactos, incompletos, fraccionados,
          que induzcan a error, o aquellos cuyo tratamiento esté expresamente
          prohibido o no haya sido autorizado. 2. A autorizar el
          diligenciamiento del formato de manejo de datos personales, los cuales
          serán conservados hasta que el titular o la empresa lo consideren
          necesario. 3. A que se informe cuál es el manejo que se le dará a sus
          datos y garantizarle la seguridad y privacidad de los mismos. 4. A que
          se le garantice que sus datos personales no estarán disponibles en
          Redes Sociales o medios de comunicación. 5. A que se le informe que
          hay una persona natural o jurídica encargada de acceder a su
          información. 6. A que se les informe que algunos de sus datos se
          suministran a otras entidades con el sólo objetivo de cumplir con la
          relación comercial existente. 7. A que se le suministren los datos del
          encargado de la información cuando estos sean transmitidos a otra
          persona natural o jurídica para el normal desarrollo de la relación
          comercial. 8. A que se informe que la información recibida se
          conservará hasta que el titular o el responsable decidan lo contrario.
          9. A que se le Suministre la información de cuál es el proceso, en
          caso que desee bloquear, corregir, actualizar o suprimir la
          información que reposa en las bases de datos. 10.A que se le informe a
          quien se debe dirigir en caso que requiera: consultar, corregir,
          actualizar o suprimir la información. 11.A que se Informen los canales
          por los cuales pueden solicitar información acerca de sus datos
          (Teléfono 4487696, departamento de Servicio Al Cliente, Correo
          electrónico: Contacto@juanabonita.com.co 12.A que se le Informe en
          caso de que se realice alguna modificación a la política de
          tratamiento de datos personales, lo cual estará disponible en la
          Página www.juanabonita.com.co. 13.A conocer que su información se
          conserva en un lugar seguro y que nadie fuera de los relacionados como
          encargados de trámites la manipulan. 14.A que se le informe que los
          datos relacionados en la solicitud de crédito no serán divulgados sin
          autorización. 15.A Presentar ante la Superintendencia de Industria y
          Comercio, o la entidad que hiciere sus veces, quejas por infracciones
          a lo dispuesto en la ley 1581 de 2012 y las demás normas que la
          modifiquen, adicionen o complementen, previo trámite de consulta o
          requerimiento ante FLOR’ES ELEGANCIA INTIMA S.A.S. La atención de
          peticiones, consultas, quejas o reclamos se pueden comunicar al
          teléfono 4487696 o al correo contacto@juanabonita.com.co
        </p>
        <br />
        <p class="font-bold">DERECHOS DE LOS NIÑOS Y ADOLESCENTES</p>
        <br />
        <br />
        <p>
          1. Datos de menores de edad. FLOR’ES ELEGANCIA INTIMA S.A.S. No
          reconocerá como CLIENTE a un niño, niña o adolescente 2. En caso de
          que FLOR’ES ELEGANCIA INTIMA S.A.S. determine que un niño, niña o
          adolescente solicita ser su CLIENTE, rechazará de plano tal solicitud
          y eliminará de manera inmediata los datos por él suministrados. 3. No
          obstante FLOR’ES ELEGANCIA INTIMA S.A.S. reconocerá como otorgante de
          datos a adolescentes y podrá utilizar sus datos para efectos
          comerciales, pero NUNCA los pondrá a circular; cuando se efectué
          tratamiento de datos de menores, en los términos establecidos en este
          artículo se presume que sus padres han dado autorización y han emitido
          concepto favorable sobre la madurez que estos tienen en la opinión,
          escucha, autonomía y capacidad de determinar su percepción frente a
          los productos que produce FLOR’ES ELEGANCIA INTIMA S.A.S.
        </p>
        <p class="font-bold">
          POLÍTICAS PARA EL TRATAMIENTO DE DATOS PERSONALES PROVEEDORES
        </p>
        <p>
          La Compañía Flores Elegancia intima ubicada en la dirección Calle 57
          Sur 48-24 interior 103-104 Sabaneta - Ant. Teléfono 4487696
          TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS: 1. Verificación de
          Referencia comerciales. 2. Suministro de referencias comerciales
          solicitados por otras empresas. 3. Inscripción al banco para pago de
          facturas. 4. Envió por cualquiera de los medios de comunicación
          existentes información sobre todo evento que se considere importante
          para las partes. Confidencialidad de la información. EL PROVEEDOR, y
          FLOR’ES ELEGANCIA INTIMA S.A.S por la suscripción del contrato verbal
          o escrito, quedarán obligados a mantener bajo reserva, sin revelación
          a terceras personas y estrictamente confidencial, evitando por ende en
          que se haga de dominio público, toda la información que sea de
          propiedad de cualquiera de ellas y le haya sido suministrada o tenga
          conocimiento de ella con el objeto de desarrollar el contrato. Por
          ende, EL PROVEEDOR, y FLOR’ES ELEGANCIA INTIMA S.A.S podrán hacer uso
          de ella, para la ejecución del objeto del contrato, debiendo proteger
          dicha información confidencial, con el fin de que no sea revelada y
          conocida por terceros, custodiándola con la diligencia y cuidado
          apropiados a las circunstancias; no obstante lo cual, podrá revelarla
          a las autoridades competentes para los fines y en los casos previstos
          expresamente en la ley, cuando ello sea necesario en cumplimiento de
          obligaciones y deberes legales y siempre y cuando medie orden judicial
          o de autoridad competente, pero en tal caso, Si alguna de las partes
          es requerida por autoridad legal competente, deberán informar del
          hecho a LA PARTE no requerida, indicando la autoridad a la cual será
          revelada la información confidencial, así como los motivos que
          justifican su proceder.
        </p>
        <br /><br />
        <p class="font-bold">DERECHOS QUE LE ASISTEN AL PROVEEDOR</p>
        <br />
        <p>
          1. A autorizar el diligenciamiento del formato de manejo de datos
          personales. 2. A que se le garantice la seguridad de su información
          bancaria 3. A que se le garantice que su información personal no va a
          ser pasada a terceros 4. A que se le informe que los datos no serán
          divulgados sin autorización 5. A que se le informe quien es la persona
          encargada de acceder a su información. 6. A que se informe cuál es el
          manejo que se le dará a sus datos y garantizarle la seguridad y
          privacidad de los mismos. 7. A conocer, actualizar y rectificar de
          manera gratuita los datos que se encuentren incorporados en nuestra
          base de datos. 8. A solicitarnos prueba de la autorización otorgada a
          FLOR’ES ELEGANCIA INTIMA S.A.S para custodiar y tratar sus datos,
          excepto cuando no se requiere tal autorización. 9. A que se Informen
          los canales por los cuales pueden solicitar información acerca de sus
          datos (Teléfono 4487696, departamento de Compras, Correo electrónico:
          Contacto@juanabonita.com.co 10.A suprimir el dato cuando a ello haya
          lugar. 11.A que se le Informe en caso de que se realice alguna
          modificación a la política de tratamiento de datos personales lo cual
          estará disponible en la Página www.juanabonita.com.co. La atención de
          peticiones, consultas, quejas o reclamos se pueden comunicar al
          teléfono 4487696 o al correo contacto@juanabonita.com.co.
        </p>
        <br />
        <p class="font-bold">
          POLÍTICAS PARA EL TRATAMIENTO DE DATOS PERSONALES EMPLEADOS
        </p>
        <br />
        <p>
          Política de Tratamiento de la información regula el tratamiento, la
          recolección, el almacenamiento, el uso, la circulación y la supresión
          de aquella información que FLOR’ES ELEGANCIA INTIMA reciba de sus
          EMPLEADOS y de los ASPIRANTES en los distintos puntos de recolección
          de información; de acuerdo con las disposiciones contenidas en la Ley
          Estatutaria 1581 de 2012.
        </p>
        <p class="font-bold">TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS:</p>
        <br />
        <p>
          1. Verificación de Referencia laborales. 2. Suministro de certificados
          laborales por otras empresas. 3. Envío de información a Eps, Cajas de
          compensación, fondo de pensiones y Cesantías. 4. Inscripción al banco
          para pago de nómina. 5. Envió por cualquiera de los medios de
          comunicación existentes información sobre todo evento que se considere
          importante para las partes.
        </p>
        <br />
        <br />
        <p class="font-bold">DERECHOS QUE LE ASISTEN AL EMPLEADO.</p>
        <br />
        <p>
          1. A autorizar el diligenciamiento del formato de manejo de datos
          personales. 2. Garantizar a los empleados la privacidad de sus datos
          personales y el de su grupo familiar. 3. Garantizar la privacidad de
          sus ingresos, embargos, descuentos de nómina y todos aquellos que sean
          necesarios para el cumplimiento de la relación laboral. 4. A que se
          les informe que durante la permanencia en las instalaciones de la
          organización son gravados permanentemente a través de un CCTV. 5. A
          que se les informe que algunos de sus datos se suministran a otras
          entidades como Eps, caja de compensación, cesantías, AFP, Banco, y
          todos aquellos que sean necesarios para el cumplimiento de la relación
          laboral. 6. A Solicitar prueba de la autorización otorgada a FLOR’ES
          ELEGANCIA INTIMA S.A.S. para custodiar y tratar sus datos, excepto
          cuando no se requiere tal autorización. 7. A que se les informe si el
          algún evento sus fotografías o videos van a ser subidos a redes
          sociales. 8. A que se Informen los canales por los cuales pueden
          solicitar información acerca de sus datos (Teléfono 4487696,
          departamento de Talento humano, Correo electrónico:
          Contacto@juanabonita.com.co 9. A conocer, actualizar, rectificar y
          suprimir de manera gratuita los datos que se encuentren incorporados
          en la base de datos de FLOR’ES ELEGANCIA INTIMA S.A.S. 10.A Ser
          informado, previa solicitud, del uso que se le ha dado a sus datos
          personales 11.A Revocar expresamente y en cualquier tiempo, la
          autorización para el tratamiento de sus datos. Tal revocación no podrá
          ser solicitada cuando el empleado se encuentre trabajando actualmente.
          12.A que se le Informe en caso de que se realice alguna modificación a
          la política de tratamiento de datos personales lo cual estará
          disponible en la Página www.juanabonita.com.co La atención de
          peticiones, consultas, quejas o reclamos se pueden comunicar al
          teléfono 4487696 o al correo contacto@juanabonita.com.co
        </p>
        <p class="font-bold">
          VII. DEBERES DE FLOR’ES ELEGANCIA INTIMA S.A.S COMO RESPONSABLE Y
          ENCARGADA DEL TRATAMIENTO DE LOS DATOS PERSONALES
        </p>
        <br />
        <br />
        <p>
          FLOR’ES ELEGANCIA INTIMA S.A.S. reconoce la titularidad que de los
          datos personales ostentan las personas y en consecuencia ellas de
          manera exclusiva pueden decidir sobre los mismos. Por lo tanto,
          FLOR’ES ELEGANCIA INTIMA S.A.S utilizará los datos personales para el
          cumplimiento de las finalidades autorizadas expresamente por el
          titular o por las normas vigentes. En el tratamiento y protección de
          datos personales, FLOR’ES ELEGANCIA INTIMA S.A.S tendrá los siguientes
          deberes, sin perjuicio de otros previstos en las disposiciones que
          regulen o lleguen a regular esta materia: a. Garantizar al titular, en
          todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
          b. Solicitar y conservar, copia de la respectiva autorización otorgada
          por el titular para el tratamiento de datos personales. c. Informar
          debidamente al titular sobre la finalidad de la recolección y los
          derechos que le asisten en virtud de la autorización otorgada. d.
          Conservar la información bajo las condiciones de seguridad necesarias
          para impedir su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento. e. Garantizar que la información sea veraz,
          completa, exacta, actualizada, comprobable y comprensible. f.
          Actualizar oportunamente la información, atendiendo de esta forma
          todas las novedades respecto de los datos del titular. Adicionalmente,
          se deberán implementar todas las medidas necesarias para que la
          información se mantenga actualizada. g. Rectificar la información
          cuando sea incorrecta y comunicar lo pertinente. h. Respetar las
          condiciones de seguridad y privacidad de la información del titular.
          i. Tramitar las consultas y reclamos formulados en los términos
          señalados por la ley. j. Identificar cuando determinada información se
          encuentra en discusión por parte del titular k. Informar a solicitud
          del titular sobre el uso dado a sus datos. l. Informar a la autoridad
          de protección de datos cuando se presenten violaciones a los códigos
          de seguridad y existan riesgos en la administración de la información
          de los titulares. m.Cumplir los requerimientos e instrucciones que
          imparta la Superintendencia de Industria y Comercio sobre el tema en
          particular. n. Usar únicamente datos cuyo tratamiento esté previamente
          autorizado de conformidad con lo previsto en la ley 1581 de 2012. o.
          Velar por el uso adecuado de los datos personales de los niños, niñas
          y adolescentes, en aquellos casos en que se entra autorizado el
          tratamiento de sus datos. p. Registrar en la base de datos las leyenda
          "reclamo en trámite" en la forma en que se regula en la ley. q.
          Insertar en la base de datos la leyenda "información en discusión
          judicial" una vez notificado por parte de la autoridad competente
          sobre procesos judiciales relacionados con la calidad del dato
          personal. r. Abstenerse de circular información que esté siendo
          controvertida por el titular y cuyo bloqueo haya sido ordenado por la
          Superintendencia de Industria y Comercio s. Permitir el acceso a la
          información únicamente a las personas que pueden tener acceso a ella.
          t. Usar los datos personales del titular sólo para aquellas
          finalidades para las que se encuentre facultada debidamente y
          respetando en todo caso la normatividad vigente sobre protección de
          datos personales.
        </p>
        <br />
        <br />
        <p class="font-bold">VIII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR</p>
        <br />
        <br />
        <p>
          FLOR’ES ELEGANCIA INTIMA S.A.S requiere del consentimiento libre,
          previo, expreso e informado del titular de los datos personales para
          el tratamiento de los mismos, exceptos en los casos expresamente
          autorizados en la ley, a saber: a. Información requerida por una
          entidad pública o administrativa en ejercicio de sus funciones legales
          o por orden judicial. b. Datos de naturaleza pública. c. Casos de
          urgencia médica o sanitaria. d. Tratamiento de información autorizado
          por la ley para fines históricos, estadísticos o científicos. e. Datos
          relacionados con el Registro Civil de las Personas Manifestación de la
          autorización La autorización a FLOR’ES ELEGANCIA INTIMA S.A.S para el
          tratamiento de los datos personales será otorgada por: i. El titular,
          quien deberá acreditar su identidad en forma suficiente por los
          distintos medios que le ponga a disposición FLOR’ES ELEGANCIA INTIMA
          S.A.S ii. Los causahabientes del titular, quienes deberán acreditar
          tal calidad. iii. El representante y/o apoderado del titular, previa
          acreditación de la representación o apoderamiento. iv. Otro a favor o
          para el cual el titular hubiere estipulado.
        </p>
        <br /><br />
        <br />
        <p>
          Medios para otorgar la autorización FLOR’ES ELEGANCIA INTIMA S.A.S
          obtendrá la autorización mediante diferentes medios, entre ellos el
          documento físico, electrónico, mensaje de datos, Internet, Sitios Web,
          o en cualquier otro formato que en todo caso permita la obtención del
          consentimiento mediante conductas inequívocas a través de las cuales
          se concluya que de no haberse surtido la misma por parte del titular o
          la persona legitimada para ello, los datos no se hubieran almacenado o
          capturado en la base de datos. La autorización será solicitada por
          FLOR’ES ELEGANCIA INTIMA S.A.S de manera previa al tratamiento de los
          datos personales. Prueba de la autorización FLOR’ES ELEGANCIA INTIMA
          S.A.S conservará la prueba de la autorización otorgada por los
          titulares de los datos personales para su tratamiento, para lo cual
          utilizará los mecanismos disponibles a su alcance en la actualidad al
          igual que adoptará las acciones necesarias para mantener el registro
          de la forma y fecha y en la que obtuvo ésta. En consecuencia FLOR’ES
          ELEGANCIA INTIMA S.A.S podrá establecer archivos físicos o
          repositorios electrónicos realizados de manera directa o a través de
          terceros contratados para tal fin. Revocatoria de la autorización: los
          titulares de los datos personales pueden en cualquier momento revocar
          la autorización otorgada a FLOR’ES ELEGANCIA ÍNTIMA S.A.S para el
          tratamiento de sus datos personales o solicitar la supresión de los
          mismos, siempre y cuando no lo impida una disposición legal o
          contractual. FLOR’ES ELEGANCIA INTIMA S.A.S establecerá mecanismos
          sencillos y gratuitos que permitan al titular revocar su autorización
          o solicitar la supresión sus datos personales, al menos por el mismo
          medio por el que lo otorgó. Para lo anterior, deberá tenerse en cuenta
          que la revocatoria del consentimiento puede expresarse, por una parte,
          de manera total en relación con las finalidades autorizadas, y por lo
          tanto FLOR’ES ELEGANCIA INTIMA S.A.S deberá cesar cualquier actividad
          De tratamiento de los datos; y por la otra de manera parcial en
          relación con ciertos tipos de tratamiento, en cuyo caso serán estos
          sobre los que cesarán las actividades de tratamiento, como para fines
          publicitarios, entre otros. En este último caso, FLOR’ES ELEGANCIA
          INTIMA S.A.S podrá continuar tratando los datos personales para
          aquellos fines en relación con los cuales el titular no hubiera
          revocado su consentimiento.
        </p>
        <br />
        <br />
        <p class="font-fold">
          IX. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DEL
          MISMO
        </p>
        <br />
        <p>
          El tratamiento de los datos personales de clientes, proveedores,
          empleados, clientes inactivos, ex proveedores y ex empleados, o de
          cualquier persona con la cual FLOR’ES ELEGANCIA INTIMA S.A.S tuviere
          establecida o estableciera una relación, permanente u ocasional, lo
          realizará en el marco legal que regula la materia y en virtud de su
          condición de confeccionador y comercializador de prendas de ropa de
          vestir femenina y masculina, y serán todos los necesarios para el
          cumplimiento de la misión institucional. En todo caso, los datos
          personales podrán ser recolectados y tratados para: a. Realizar el
          envío de información relacionada con: catálogos, productos. b.
          comercializar, nuevos productos, actividades comerciales, estrategias
          comerciales, programas de fidelización, estados de cuenta, comunicados
          sobre estados de la relación comercial, políticas que se adopten y
          afecten directamente la relación, actividades, noticias, contenidos
          por área de interés, y demás bienes ofrecidos por FLOR’ES ELEGANCIA
          INTIMA S.A.S. c. Desarrollar la misión de FLOR’ES ELEGANCIA INTIMA
          S.A.S conforme a sus estatutos. d. Cumplir con la normatividad vigente
          en Colombia, entidades acreditadoras o las autoridades locales. e.
          Cumplir las normas aplicables a proveedores y contratistas, incluyendo
          pero sin limitarse a las tributarias y comerciales. f. Cumplir lo
          dispuesto por el ordenamiento jurídico colombiano en materia laboral y
          de seguridad social, entre otras, aplicables a ex empleados, empleados
          actuales y candidatos a futuro empleo. g. Realizar encuestas
          relacionadas con los servicios o bienes de FLOR’ES ELEGANCIA INTIMA
          S.A.S. h. Mantener en contacto a los actores que ya no tienen relación
          directa o intereses afines. Datos sensibles Para el caso de datos
          personales sensibles, FLOR’ES ELEGANCIA INTIMA S.A.S podrá hacer uso y
          tratamiento de ellos cuando: a. El titular haya dado su autorización
          explícita, salvo en los casos que por ley no sea requerido el
          otorgamiento de dicha autorización. b. El tratamiento sea necesario
          para salvaguardar el interés vital del Titular y este se encuentre
          física o jurídicamente incapacitado. En estos eventos, los
          representantes legales deberán otorgar su autorización El tratamiento
          sea efectuado en el curso de las actividades legítimas y con las
          debidas garantías por parte de una fundación, ONG, asociación o
          cualquier otro organismo sin ánimo de lucro, cuya finalidad sea
          política, filosófica, religiosa o sindical, siempre que se refieran
          exclusivamente d. a las personas que mantengan contactos regulares por
          razón de su finalidad. En estos eventos, los datos no se podrán
          suministrar a terceros sin la autorización del titular. e. El
          Tratamiento se refiera a datos que sean necesarios para el
          reconocimiento, ejercicio o defensa de un derecho en un proceso
          judicial. f. El Tratamiento tenga una finalidad histórica, estadística
          o científica. En este evento deberán adoptarse las medidas conducentes
          a la supresión de identidad de los titulares. Sin perjuicio de las
          excepciones previstas en la ley, en el tratamiento de datos sensibles
          se requiere la autorización previa, expresa e informada del titular,
          la cual deberá ser obtenida por cualquier medio que pueda ser objeto
          de consulta y verificación posterior.
        </p>
        <br />
        <br />
        <p class="font-bold">X. AVISO DE PRIVACIDAD</p>
        <br />
        <br />
        <p>
          Somos una empresa comprometida con la privacidad y la seguridad de los
          Datos Personales de nuestros CLIENTES, PROVEEDORES, EMPLEADOS Y
          USUARIOS en los términos de la Ley 1581 de 2012 y el Decreto 1377 de
          2013; en este sentido, se ha adoptado la siguiente ‘política de
          privacidad y protección de datos personales’, en la cual, nuestros
          clientes, proveedores, empleados y usuarios, podrán conocer el
          procedimiento y tratamiento de sus Datos Personales que ingresan en
          nuestras bases de datos, así como los derechos, garantías y los
          procedimientos para hacerlos efectivos. A través de este documento se
          comunica al titular la información relacionada con la existencia de
          las políticas de tratamiento de información de FLOR’ES ELEGANCIA
          INTIMA S.A.S y que le serán aplicables, la forma de acceder a las
          mismas y las características del tratamiento que se pretende dar a los
          datos personales. El aviso de privacidad deberá contener, como mínimo,
          la siguiente información: a. La identidad, domicilio y datos de
          contacto del responsable del tratamiento. b. El tipo de tratamiento al
          cual serán sometidos los datos y la finalidad del mismo. c. c. Los
          derechos del titular Somos una empresa comprometida con la privacidad
          y la seguridad de los Datos Personales de nuestros CLIENTES,
          PROVEEDORES, EMPLEADOS Y USUARIOS en los términos de la Ley 1581 de
          2012 y el Decreto 1377 de 2013; en este sentido, se ha adoptado la
          siguiente ‘política de privacidad y protección de datos personales’,
          en la cual, nuestros clientes, proveedores, empleados y usuarios,
          podrán conocer el procedimiento y tratamiento de sus Datos Personales
          que ingresan en nuestras bases de datos, así como los derechos,
          garantías y los procedimientos para hacerlos efectivos. A través de
          este documento se comunica al titular la información relacionada con
          la existencia de las políticas de tratamiento de información de
          FLOR’ES ELEGANCIA INTIMA S.A.S y que le serán aplicables, la forma de
          acceder a las mismas y las características del tratamiento que se
          pretende dar a los datos personales. El aviso de privacidad deberá
          contener, como mínimo, la siguiente información: a. La identidad,
          domicilio y datos de contacto del responsable del tratamiento. b. El
          tipo de tratamiento al cual serán sometidos los datos y la finalidad
          del mismo. c. c. Los derechos del titular
        </p>
        <br />
        <p class="font-bold">XI. GARANTÍAS DEL DERECHO DE ACCESO</p>
        <br />
        <p>
          Para garantizar el derecho de acceso del titular de los datos, FLOR’ES
          ELEGANCIA INTIMA S.A.S pondrá a disposición de éste, previa
          acreditación de su identidad, legitimidad, o personalidad de su
          representante, sin costo o erogación alguna, de manera pormenorizada y
          detallada, los respectivos datos personales a través de todo tipo de
          medio, incluyendo los medios electrónicos que permitan el acceso
          directo del titular a ellos. Dicho acceso deberá ofrecerse sin límite
          alguno y le deben permitir al titular la posibilidad de conocerlos y
          actualizarlos en línea.
        </p>
        <br />
        <p class="font-bold">
          XII. PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS, PETICIONES
          DE RECTIFICACIÓN, ACTUALIZACIÓN Y SUPRESIÓN DE DATOS
        </p>
        <br />
        <p>
          1. Consultas: Los titulares o sus causahabientes podrán consultar la
          información personal del titular que repose en FLOR’ES ELEGANCIA
          INTIMA S.A.S, quien suministrará toda la información contenida en el
          registro individual o que esté vinculada con la identificación del
          Titular. Con respecto a la atención de solicitudes de consulta de
          datos personales FLOR’ES ELEGANCIA INTIMA S.A.S garantiza:  Habilitar
          medios de comunicación electrónica u otros que considere pertinentes.
           Establecer formularios, sistemas y otros métodos simplificados, los
          cuales deberán ser informados en el aviso de privacidad.  Utilizar
          los servicios de atención al cliente o de reclamaciones que tiene en
          operación.  En cualquier caso, independientemente del mecanismo
          implementado para la atención de solicitudes de consulta, las mismas
          serán atendidas en un término máximo de diez (10) días hábiles
          contados a partir de la fecha de su recibo. Cuando no fuere posible
          atender la consulta dentro de dicho término, se informará al
          interesado antes del vencimiento de los 10 días, expresando los
          motivos de la demora y señalando la fecha en que se atenderá su
          consulta, la cual en ningún caso podrá superar los cinco (5) días
          hábiles siguientes al vencimiento del primer plazo.  Las consultas
          podrán formularse al correo contacto@juanabonita.com.co o mediante
          comunicación escrita a la calle 57 sur # 48 – 24 int 103-104, en el
          municipio de Sabaneta – Antioquia, dirigido al encargado de servicio
          al cliente  Reclamos El Titular o sus causahabientes que consideren
          que la información contenida en una base de datos debe ser objeto de
          corrección, actualización o supresión, o cuando adviertan el presunto
          incumplimiento de cualquiera de los deberes contenidos en la ley,
          podrán presentar un reclamo ante FLOR’ES ELEGANCIA INTIMA S.A.S, el
          cual será tramitado bajo las siguientes reglas: El reclamo del Titular
          se formulará mediante solicitud dirigida a FLOR’ES ELEGANCIA INTIMA
          S.A.S al correo electrónico contacto@juanabonita.com.co o mediante
          comunicación escrita a la calle 57 sur # 48 – 24 int 103-104, en el
          municipio de Sabaneta – Antioquia, dirigido al encargado de servicio
          al cliente, con la identificación del titular, la descripción de los
          hechos que dan lugar al reclamo, la dirección, y acompañando los
          documentos que se quiera hacer valer. Si el reclamo resulta
          incompleto, se requerirá al interesado dentro de los cinco (5) días
          siguientes a la recepción del reclamo para que subsane las fallas.
          Transcurridos dos (2) meses desde la fecha del requerimiento, sin que
          el solicitante presente la información requerida, se entenderá que ha
          desistido del reclamo. En caso de que quien reciba el reclamo no sea
          competente para resolverlo, dará traslado a quien corresponda en un
          término máximo de dos (2) días hábiles e informará de la situación al
          interesado. Una vez recibido el reclamo completo, éste se catalogará
          con la etiqueta "reclamo en trámite" y el motivo del mismo, en un
          término no mayor a dos (2) días hábiles. Dicha etiqueta se mantendrá
          hasta que el reclamo sea decidido. El término máximo para atender el
          reclamo será de quince (15) días hábiles contados a partir del día
          siguiente a la fecha de su recibo. Cuando no fuere posible atender el
          reclamo dentro de dicho término, se informará al interesado los
          motivos de la demora y la fecha en que se atenderá su reclamo, la cual
          en ningún caso podrá superar los ocho (8) días hábiles siguientes al
          vencimiento del primer término. Petición de actualización y/o
          rectificación FLOR’ES ELEGANCIA INTIMA S.A.S rectificará y
          actualizará, a solicitud del titular, la información de éste que
          resulte ser incompleta o inexacta, de conformidad con el procedimiento
          y los términos antes señalados, para lo cual se tendrá en cuenta: El
          titular deberá allegar la solicitud al correo electrónico
          contacto@juanabonita.com.co o mediante comunicación escrita a la calle
          57 sur #48–24 int 103, en el municipio de Sabaneta–Antioquia, dirigido
          al encargado de servicio al cliente indicando la actualización y/o
          rectificación a realizar y aportará la documentación que sustente su
          petición. FLOR’ES ELEGANCIA INTIMA S.A.S podrá habilitar mecanismos
          que le faciliten el ejercicio de este derecho al titular, siempre y
          cuando éstos lo beneficien. En consecuencia, se podrán habilitar
          medios electrónicos u otros que considere pertinentes, los cuales
          serán informados en el aviso de privacidad y se pondrán a disposición
          de los interesados. a. Petición de supresión de datos El titular de
          los datos personales tiene el derecho de solicitar a FLOR’ES ELEGANCIA
          ÍNTIMA S.A.S su supresión (eliminación) en cualquiera de los
          siguientes eventos: b. Considere que los mismos no están siendo
          tratados conforme a los principios, deberes y obligaciones previstas
          en la normatividad vigente. c. Hayan dejado de ser necesarios o
          pertinentes para la finalidad para la cual fueron recabados. d. Se
          haya superado el periodo necesario para el cumplimiento de los fines
          para los que fueron recabados. Esta supresión implica la eliminación
          total o parcial de la información personal de acuerdo con lo
          solicitado por el titular en los registros, archivos, bases de datos o
          tratamientos realizados por FLOR’ES ELEGANCIA INTIMA S.A.S. Sin
          embargo este derecho del titular no es absoluto y en consecuencia
          FLOR’ES ELEGANCIA INTIMA S.A.S podrá negar el ejercicio del mismo
          cuando: a. El titular tenga un deber legal o contractual de permanecer
          en la base de datos. b. La eliminación de datos obstaculice
          actuaciones judiciales o administrativas vinculadas a obligaciones
          fiscales, la investigación y persecución de delitos o la actualización
          de sanciones administrativas. c. Los datos sean necesarios para
          proteger los intereses jurídicamente tutelados del titular; para
          realizar una acción en función del interés público, o para cumplir con
          una obligación legalmente adquirida por el titular. XIII. REGISTRO
          NACIONAL DE BASE DE DATOS FLOR’ES ELEGANCIA INTIMA S.A.S, se reserva,
          en los eventos contemplados en la ley y en sus estatutos y reglamentos
          internos, la facultad de mantener y catalogar determinada información
          que repose en sus bases o bancos de datos, como confidencial de
          acuerdo con las normas vigentes, sus estatutos y reglamentos, todo lo
          anterior y en consonancia su autonomía. FLOR’ES ELEGANCIA INTIMA
          S.A.S, procederá de acuerdo con la normatividad vigente y la
          reglamentación que para tal fin expida el Gobierno Nacional, a
          realizar el registro de sus bases de datos, ante El Registro Nacional
          de Bases de Datos (RNBD) que será administrado por la Superintendencia
          de Industria y Comercio. El RNBD., es el directorio público de las
          bases de datos sujetas a Tratamiento que operan en el país; y que será
          de libre consulta para los ciudadanos, de acuerdo con la normatividad
          que para tal efecto expida el Gobierno Nacional. XIV. SEGURIDAD DE LA
          INFORMACIÓN Y MEDIDAS DE SEGURIDAD Dando cumplimiento al principio de
          seguridad establecido en la normatividad vigente, FLOR’ES ELEGANCIA
          INTIMA S.A.S adoptará las medidas técnicas, humanas y administrativas
          que sean necesarias para otorgar seguridad a los registros evitando su
          adulteración, pérdida, consulta, uso o acceso no autorizado o
          fraudulento. XV. UTILIZACIÓN Y TRANSFERENCIA INTERNACIONAL DE DATOS
          PERSONALES E INFORMACIÓN PERSONAL POR PARTE DE FLOR’ES ELEGANCIA
          INTIMA S.A.S En cumplimiento de la misión institucional y del plan
          estratégico de desarrollo de FLOR’ES ELEGANCIA INTIMA S.A.S, y
          atendiendo a la naturaleza de las relaciones permanentes u ocasionales
          que cualquier persona titular de datos personales pueda tener para con
          FLOR’ES ELEGANCIA INTIMA S.A.S, ésta podrá realizar la transferencia y
          transmisión, incluso internacional, de la totalidad de los datos
          personales, siempre y cuando se cumplan los requerimientos legales
          aplicables; y en consecuencia los titulares con la aceptación de la
          presente política, autorizan expresamente para transferir y
          transmitir, incluso a nivel internacional, los datos personales. Los
          datos serán transferidos, para todas las relaciones que puedan
          establecerse con FLOR’ES ELEGANCIA INTIMA S.A.S. Para la transferencia
          internacional de datos personales de los titulares, FLOR’ES ELEGANCIA
          INTIMA S.A.S tomará las medidas necesarias para que los terceros
          conozcan y se comprometan a observar la presente política, bajo el
          entendido que la información personal que reciban, únicamente podrá
          ser utilizada para asuntos directamente relacionados con FLOR’ES
          ELEGANCIA INTIMA S.A.S y solamente mientras ésta dure y no podrá ser
          usada o destinada para propósito o fin diferente. Para la
          transferencia internacional de datos personales se observará lo
          previsto en el artículo 26 de la Ley 1581 de 2012. Las transmisiones
          internacionales de datos personales que efectúe FLOR’ES ELEGANCIA
          INTIMA S.A.S, no requerirán ser informadas al titular ni contar con su
          consentimiento cuando medie un contrato de transmisión de datos
          personales de conformidad al artículo 25 del Decreto 1377 de 2013.
          FLOR’ES ELEGANCIA INTIMA S.A.S, también podrá intercambiar información
          personal con autoridades gubernamentales o públicas de otro tipo
          (incluidas, entre otras autoridades judiciales o administrativas,
          autoridades fiscales y organismos de investigación penal, civil,
          administrativa, disciplinaria y fiscal), y terceros participantes en
          procedimientos legales civiles y sus contadores, auditores, abogados y
          otros asesores y representantes, porque es necesario o apropiado: (a)
          para cumplir con las leyes vigentes, incluidas las leyes distintas a
          las de su país de residencia; (b) para cumplir con procesos jurídicos;
          (c) para responder las solicitudes de las autoridades públicas y del
          gobierno, y para responder las solicitudes de las autoridades públicas
          y del gobierno distintas a las de su país de residencia; (d) para
          hacer cumplir nuestros términos y condiciones; (e) para proteger
          nuestras operaciones; (f) para proteger nuestros derechos, privacidad,
          seguridad o propiedad, los suyos o los de terceros; y (g) obtener las
          indemnizaciones aplicables o limitar los daños y perjuicios que nos
          puedan afectar.
        </p>
        <br />
        <p class="font-bold">
          XVI. RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS PERSONALES
        </p>
        <br />
        <p>
          FLOR’ES ELEGANCIA INTIMA S.A.S será la responsable del tratamiento de
          los datos personales. El departamento de Servicio al cliente será el
          encargado del tratamiento de los datos personales, correo electrónico
          contacto@juanabonita.com.co por cuenta de FLOR’ES ELEGANCIA INTIMA
          S.A.S
        </p>
        <br />
        <p class="font-bold">XVII. VIGENCIA</p>
        <br />
        <br />
        <p>
          La presente política rige a partir del 25 de junio de 2017 y deja sin
          efectos los reglamentos o manuales especiales que se hubiesen podido
          adoptar por instancias académicas y/o administrativas en sus
          instalaciones.
        </p>
        <br />
        <br /><br />
        <p class="font-bold text-center">
          OMAR ALONSO DUQUE GOMEZ <br />
          Representante legal <br />
          FLOR’ES ELEGANCIA INTIMA S.A.S. <br />
          900365409 – 1 <br />
          Calle 57 sur # 48 – 24 C.I. METROSABANA / teléfono 448 76 96
        </p>
        <div class="grid justify-items-center">
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import img from "../assets/logos/LogoJuana.png";
import { ref } from "@vue/reactivity";
import store, { useStore } from "vuex";
export default {
  name: "noticeModal",

  setup() {
    const store = useStore();
    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center  w-4/4 relative mx-2 z-50  px-3 py-2 flex items-center justify-center rounded absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-8 lg:mt-10"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch p-3 left-0 top-0 bottom-0 right-0  fixed bg-opacity-50 bg-gray-900"
    );
    const cerrarTerminos = () => {
      store.commit("terminosModal");
    };
    return {
      classModal,
      fondoclass,
      cerrarTerminos,
      img,
    };
  },
};
</script>

<style>
</style>